import { Dispatch } from 'redux'
import { Alert } from '../reducers/alerts'
import { v4 as uuidv4 } from 'uuid'
import { ADD_ALERT, REMOVE_ALERT } from '../types'

export const createAlert = (message: string) => (dispatch: Dispatch) => {
  const alert: Alert = {
    message,
    id: uuidv4(),
  }

  dispatch({
    type: ADD_ALERT,
    payload: alert,
  })

  setTimeout(() => {
    dispatch({
      type: REMOVE_ALERT,
      payload: alert.id,
    })
  }, 3000)
}

export const removeAlert = (id: string) => (dispatch: Dispatch) => {
  dispatch({
    type: REMOVE_ALERT,
    payload: id,
  })
}
