import { Button, Card, Header } from 'semantic-ui-react'
import SocialLinks from '../../components/SocialLinks'
import { Link } from 'react-router-dom'
import ReversibleBuildingDesign from '../../images/ReversibleBuildingDesign.jpeg'
import CircularEconomy from '../../images/CircularEconomy.jpeg'
import CircularEconomyConstruction from '../../images/CircularEconomyConstruction.jpeg'

const CircularBuildingDesign = () => {
  return (
    <div>
      <div className="dashboard">
        <Header
          as="h1"
          style={{ backgroundColor: 'transparent', padding: '10px 0' }}
        >
          Exemplary Projects - Circular Building Projects
          <Header.Subheader style={{ backgroundColor: 'transparent' }}>
            Overview of best practices of circular building to take advantage
            of. Also a sub-category with general building projects for measuring
            regional material flows.
          </Header.Subheader>
        </Header>
        <Card.Group itemsPerRow="4" stackable doubling>
          <Card color="teal" raised>
            <img
              src={ReversibleBuildingDesign}
              height={250}
              alt="ReversibleBuildingDesign"
              className="coverImage"
            />
            <Card.Content>
              <Card.Header style={{ backgroundColor: 'white' }}>
                Guidelines
              </Card.Header>
              <Card.Meta>Reversible Building Design</Card.Meta>
              <Card.Description>
                Guildlines to design reversible circulair buildings. According
                to the authour ‘Reversibility’ is defined as process of...
              </Card.Description>
            </Card.Content>
            <Card.Content extra textAlign="center">
              <Link to="/fallback">
                <Button className="cardButtons">Read More</Button>
              </Link>
            </Card.Content>
          </Card>
          <Card color="teal" raised>
            <img
              src={CircularEconomy}
              height={250}
              alt="CircularEconomy"
              className="coverImage"
            />
            <Card.Content>
              <Card.Header style={{ backgroundColor: 'white' }}>
                Principles for Building Design
              </Card.Header>
              <Card.Meta>Circular Economy</Card.Meta>
              <Card.Description>
                This European Comission's publication on Circulair Economy
                Principles to Building informs and supports actors along the...
              </Card.Description>
            </Card.Content>
            <Card.Content extra textAlign="center">
              <Link to="/fallback">
                <Button className="cardButtons">Read More</Button>
              </Link>
            </Card.Content>
          </Card>
          <Card color="teal" raised>
            <img
              src={CircularEconomyConstruction}
              height={250}
              alt="CircularEconomyConstruction"
              className="coverImage"
            />
            <Card.Content>
              <Card.Header style={{ backgroundColor: 'white' }}>
                Design Strategies for Reversible Buildings
              </Card.Header>
              <Card.Meta>Circular Economy in Construction</Card.Meta>
              <Card.Description>
                This publication explores a new perception of future buildings
                which are dynamic/reversible structures built with exchangeable
                modules...
              </Card.Description>
            </Card.Content>
            <Card.Content extra textAlign="center">
              <Link to="/fallback">
                <Button className="cardButtons">Read More</Button>
              </Link>
            </Card.Content>
          </Card>
        </Card.Group>
      </div>
      <SocialLinks />
    </div>
  )
}

export default CircularBuildingDesign
