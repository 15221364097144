import React from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import TextInput from '../../components/TextInput'
import { useDispatch } from 'react-redux'
import { editUser } from '../../redux/actions/auth'
import { useAppSelector } from '../../redux/hooks'
import { useParams, useHistory } from 'react-router-dom'

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Invalid Email Address')
    .required('Email is required'),
  password: yup
    .string()
    .min(8, 'Must be 8 characters or more')
    .required('Password is required'),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Repeat the password'),
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
})

const EditProfile: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()
  const user = useAppSelector(state => state.AuthReducer.user)
  const { id } = useParams<{ id: string }>()
  const history = useHistory()

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
  } = useFormik({
    initialValues: {
      _id: id,
      email: `${user?.email}`,
      password: '',
      passwordConfirmation: '',
      firstName: '',
      lastName: '',
      address: {
        street1: 'Imaginary Place 1',
        street2: 'Imaginary Place 2',
        city: 'Imaginary City',
        state: 'Imaginary State',
        country: 'Imaginary Country',
        zip: '71000',
      },
    },
    validationSchema,
    onSubmit: () => {
      dispatch(editUser(values, history))
    },
  })
  return (
    <div>
      <div id="signup__container" className="ui container">
        <div className="ui raised teal segment">
          <h3 className="signup__title">Edit Profile</h3>{' '}
          <form
            onSubmit={handleSubmit}
            id="signup__form"
            className="ui fluid form"
          >
            <TextInput
              name="email"
              id="email"
              label="New Email"
              placeholder="Email"
              icon="envelope icon"
              type="text"
              value={values.email}
              touched={touched.email}
              error={errors.email}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />

            <TextInput
              name="password"
              id="password"
              label="New Password"
              placeholder="Password"
              icon="lock icon"
              type="password"
              value={values.password}
              touched={touched.password}
              error={errors.password}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <TextInput
              name="passwordConfirmation"
              id="passwordConfirmation"
              label="Confirm Password"
              placeholder="Password"
              icon="lock icon"
              type="password"
              value={values.passwordConfirmation}
              touched={touched.passwordConfirmation}
              error={errors.passwordConfirmation}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <TextInput
              name="firstName"
              id="firstName"
              label="Enter First Name"
              placeholder="First Name"
              icon="user icon"
              type="text"
              value={values.firstName}
              touched={touched.firstName}
              error={errors.firstName}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <TextInput
              name="lastName"
              id="firstName"
              label="Enter Last Name"
              placeholder="Last Name"
              icon="user icon"
              type="text"
              value={values.lastName}
              touched={touched.lastName}
              error={errors.lastName}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <button
              id="btn__signup"
              className="ui fluid button"
              style={{ backgroundColor: '#ffc000', color: 'white' }}
              type="submit"
            >
              Edit Profile
            </button>
          </form>
          <div className="ui divider"></div>
        </div>
      </div>
    </div>
  )
}

export default EditProfile
