import React from 'react'

// Components
import Header from './Header'
import Footer from './Footer'

type LayoutProps = {
  children: React.ReactNode
}

const Layout = (props: LayoutProps): JSX.Element => {
  return (
    <div className="layout">
      <Header />
      {props.children}
      <Footer />
    </div>
  )
}

export default Layout
