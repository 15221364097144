import React, { useEffect } from 'react'

/* Components */
import Project from '../../components/Project'
import AddButton from '../../components/AddButton'
import { useAppSelector } from '../../redux/hooks'
import { useDispatch } from 'react-redux'
import { fetchProjects } from '../../redux/actions/projects'
import { Divider, Header, Card, Segment } from 'semantic-ui-react'
import { getAllProjectOwners } from '../../redux/actions/users'
import '../../styles/_homePage.scss'

const HomePage = () => {
  const projects = useAppSelector(state => state.ProjectsReducer.projects)
  const userID = useAppSelector(state => state.AuthReducer.user?._id)
  const role = useAppSelector(state => state.AuthReducer.user?.role)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchProjects())

    if (role === 'SuperAdmin') {
      dispatch(getAllProjectOwners())
    }
  }, [dispatch, role])

  const displayProjects = () => {
    return projects?.map((project: IProject) => (
      <Project
        id={project._id}
        projectName={project.name}
        location={project.location}
        owner={project.owner}
        date={project.createdAt}
        key={project._id}
        elementsId={project.elementsId}
        description={project.description}
        descriptionImage={project.descriptionImage}
      />
    ))
  }
  if (role === 'ProjectOwner') {
    return (
      <div
        style={{
          width: '80%',
          margin: '0 auto 75px auto',
        }}
      >
        <Header
          as="h1"
          style={{ backgroundColor: 'transparent', padding: '10px 0' }}
        >
          Reversible BIM Projects
        </Header>
        {projects.length === 0 ? (
          <div>
            <Divider />
            <h1 id="emptyTitle">
              There are no Projects Associated with this Account
            </h1>
            <h2 id="emptyTitle">Add a New Project</h2>
          </div>
        ) : (
          <Segment>
            <Card.Group stackable doubling itemsPerRow="4">
              {displayProjects()}
            </Card.Group>
          </Segment>
        )}
      </div>
      // <div className="home">
      //   <div className="home__nav">
      //     <h1 className="home__nav--h1">Reversible BIM Projects</h1>
      //   </div>
      //   {projects.length === 0 ? (
      //     <div>
      //       <Divider />
      //       <h1 id="emptyTitle">
      //         There are no Projects Associated with this Account
      //       </h1>
      //       <h2 id="emptyTitle">Add a New Project</h2>
      //     </div>
      //   ) : (
      //     <div className="home__project-table">
      //       <Table>
      //         <Table.Header>
      //           <Table.Row>
      //             <Table.HeaderCell>Project Name</Table.HeaderCell>
      //             <Table.HeaderCell>Location</Table.HeaderCell>
      //             <Table.HeaderCell>Issued Date</Table.HeaderCell>
      //           </Table.Row>
      //         </Table.Header>
      //         <Table.Body>{displayProjects()}</Table.Body>
      //       </Table>
      //     </div>
      //   )}
      // </div>
    )
  }
  return (
    // <div className="home">
    //   <div className="home__nav">
    //     <h1 className="home__nav--h1">Reversible BIM Projects</h1>
    //     <AddButton content="Add Project" route={`/projects/add/${userID}`} />
    //   </div>
    //   {projects.length === 0 ? (
    //     <div>
    //       <Divider />
    //       <h1 id="emptyTitle">
    //         There are no Projects Associated with this Account
    //       </h1>
    //       <h2 id="emptyTitle">Add a New Project</h2>
    //     </div>
    //   ) : (
    //     <div className="home__project-table">
    //       <Table>
    //         <Table.Header>
    //           <Table.Row>
    //             <Table.HeaderCell>Project Name</Table.HeaderCell>
    //             <Table.HeaderCell>Location</Table.HeaderCell>
    //             <Table.HeaderCell>Project Owner</Table.HeaderCell>
    //             <Table.HeaderCell>Issued Date</Table.HeaderCell>
    //           </Table.Row>
    //         </Table.Header>
    //         <Table.Body>{displayProjects()}</Table.Body>
    //       </Table>
    //     </div>
    //   )}
    // </div>
    <div
      style={{
        width: '80%',
        margin: '0 auto 75px auto',
      }}
    >
      <div className="home__nav">
        <h1 className="home__nav--h1">Reversible BIM Projects</h1>
        <AddButton content="Add Project" route={`/projects/add/${userID}`} />
      </div>
      {projects.length === 0 ? (
        <div>
          <Divider />
          <h1 id="emptyTitle">
            There are no Projects Associated with this Account
          </h1>
          <h2 id="emptyTitle">Add a New Project</h2>
        </div>
      ) : (
        <Segment>
          <Card.Group stackable doubling itemsPerRow="4">
            {displayProjects()}
          </Card.Group>
        </Segment>
      )}
    </div>
  )
}

export default HomePage
