import React from 'react'
import { Link } from 'react-router-dom'
import { useAppSelector } from '../../redux/hooks'
import { Container, Segment, Table, Header, Icon } from 'semantic-ui-react'

const Profilepage = () => {
  const user = useAppSelector(state => state.AuthReducer.user)
  const id = useAppSelector(state => state.AuthReducer.user?._id)

  return (
    <Container
      style={{ backgroundColor: 'white' }}
      className="profile--container"
    >
      <Header
        as="h1"
        style={{ backgroundColor: 'white' }}
        textAlign="center"
        attached="top"
      >
        User Information
        <Header.Subheader style={{ backgroundColor: 'white' }}>
          Edit Profile and See Profile Information
        </Header.Subheader>
      </Header>
      <Segment attached>
        <Table compatct celled definitioon stackable textAlign="center">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Role</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>{user?.email}</Table.Cell>
              <Table.Cell>{user?.role}</Table.Cell>
              <Table.Cell>
                <Link to={`/user/edit/${id}`}>
                  <Icon
                    name="edit"
                    link
                    size="large"
                    color="yellow"
                    style={{
                      margin: '10px 10px',
                      title: 'Edit Material',
                    }}
                  />
                </Link>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Segment>
    </Container>
  )
}

export default Profilepage
