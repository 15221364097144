import React from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Container, Segment, Button, Header, Divider } from 'semantic-ui-react'

import TextInput from '../../components/TextInput'
import { login } from '../../redux/actions'
import { useHistory, Redirect } from 'react-router-dom'
import { useAppSelector } from '../../redux/hooks'
import { useDispatch } from 'react-redux'

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Invalid Email Address')
    .required('Email is required'),
  password: yup
    .string()
    .min(8, 'Must be 8 characters or more')
    .required('Password is required'),
})

const Login = () => {
  const dispatch = useDispatch()
  const { user } = useAppSelector(state => state.AuthReducer)
  const history = useHistory()

  const {
    handleSubmit,
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
  } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: () => {
      dispatch(login(values, history))
    },
  })

  return user ? (
    <Redirect to="/" />
  ) : (
    <div>
      <Container className="login__container">
        <Segment raised color="teal">
          <Header className="signup__title" as="h3">
            Log In
          </Header>

          <form onSubmit={handleSubmit} className="ui fluid form login__form">
            <TextInput
              name="email"
              id="email"
              label="Enter Email"
              placeholder="Email"
              icon="envelope icon"
              type="text"
              value={values.email}
              touched={touched.email}
              error={errors.email}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <TextInput
              name="password"
              id="password"
              label="Enter Password"
              placeholder="Password"
              icon="lock icon"
              type="password"
              value={values.password}
              touched={touched.password}
              error={errors.password}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <Button
              className="btn__login"
              fluid={true}
              style={{ backgroundColor: '#ffc000', color: 'white' }}
              type="submit"
            >
              LOG IN
            </Button>
          </form>

          <Divider />
          <h4 className="login__redirect">
            Hello and Welcome, Please Sign In!
          </h4>
        </Segment>
      </Container>
    </div>
  )
}

export default Login
