import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { logout } from '../redux/actions/auth'
import { Link, useHistory } from 'react-router-dom'

type hamburgerProps = {
  role: string
  destination: string
}

const Hamburger = ({ role, destination }: hamburgerProps): JSX.Element => {
  const dispatch = useDispatch()
  let history = useHistory()
  const [open, setOpen] = useState(false)

  const handleLogout = () => {
    dispatch(logout())
    history.push('/login')
  }

  return (
    <div>
      {!open ? (
        <div className="hamburger nav__mobile" onClick={() => setOpen(true)} />
      ) : (
        <div className="mobile__menu nav__mobile">
          <span className="mobile__menu--exit" onClick={() => setOpen(false)}>
            X
          </span>
          <ul className="nav__links nav__mobile">
            <li onClick={() => setOpen(false)}>
              <Link to="/home">Projects</Link>
            </li>
            <li onClick={() => setOpen(false)}>
              <Link to={destination}>{role}</Link>
            </li>
            <li onClick={() => setOpen(false)}>
              <Link to="/settings">Settings</Link>
            </li>
            <li onClick={() => setOpen(false)}>
              <Link to="/toolkit">Reversible BIM Toolkit</Link>
            </li>
          </ul>

          <button className="btn--logout nav__mobile" onClick={handleLogout}>
            LOG OUT
          </button>
        </div>
      )}
    </div>
  )
}

export default Hamburger
