import React from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import TextInput from '../../components/TextInput'
import { Form, TextArea, Input } from 'semantic-ui-react'
import { editProject } from '../../redux/actions/projects'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useAppSelector } from '../../redux/hooks'

const validationSchema = yup.object({
  name: yup
    .string()
    .min(3, 'Must be 3 characters or more')
    .required('Name is required'),
  location: yup
    .string()
    .min(5, 'Must be 5 characters or more')
    .required('Location is required'),
  ownerID: yup
    .string()
    .min(5, 'Must be 5 characters or more')
    .required('Project Owner is required'),
  description: yup
    .string()
    .min(10, 'Must be at least 10 characters long')
    .required('Description is required'),
  link: yup.string().required('Please Insert 3d View Link'),
})

const EditProjectScreen: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const project = useAppSelector(state => state.ProjectsReducer.selectedProject)
  const projectOwners = useAppSelector(
    state => state.UsersReducer.allProjectOwners
  )

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: {
      _id: id,
      name: `${project.name}`,
      ownerID: `${project.owner._id}`,
      location: `${project.location}`,
      description: `${project.description}`,
      link: `${project.url_3d}`,
      imageDES: [],
      files: [],
    },
    validationSchema,
    onSubmit: () => {
      dispatch(editProject(values, history))
    },
  })

  const selectProjectOwner = () => {
    const mappedOptions = projectOwners.map(user => {
      return (
        <option
          key={user._id}
          value={user._id}
          label={`${user.profile?.firstName} ${user.profile?.lastName}`}
        >
          {`${user.profile?.firstName} ${user.profile?.lastName}`}
        </option>
      )
    })
    return (
      <select
        name="ownerID"
        value={values.ownerID}
        onChange={handleChange}
        onBlur={handleBlur}
      >
        <option value="" label="Select Project Owner">
          Select Project Owner
        </option>
        {mappedOptions}
      </select>
    )
  }
  return (
    <div>
      <div id="signup__container" className="ui container">
        <div className="ui raised teal segment">
          <h3 className="signup__title">Edit Project</h3>
          <Form
            onSubmit={handleSubmit}
            id="signup__form"
            className="ui fluid form"
          >
            <TextInput
              name="name"
              id="name"
              label="Enter the Name of Project"
              placeholder="Name of Project"
              icon="building icon"
              type="text"
              value={values.name}
              touched={touched.name}
              error={errors.name}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <div id="login__field" className="field">
              <label className="ui__label">Image Description</label>
              <Input
                type="file"
                accept="image/png, image/jpeg, image/jpg"
                onChange={e => {
                  setFieldValue('imageDES', e.currentTarget.files)
                }}
              ></Input>
            </div>
            <div id="signup__field" className="field">
              <label className="ui__label">Select Project Owner</label>
              {selectProjectOwner()}
              {errors.ownerID && touched.ownerID ? (
                <div id="errorMessage">{errors.ownerID}</div>
              ) : null}
            </div>
            <TextInput
              name="location"
              id="location"
              label="Enter the Location"
              placeholder="Location"
              icon="map marker alternate icon"
              type="text"
              value={values.location}
              touched={touched.location}
              error={errors.location}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <div id="login__field" className="field">
              <label className="ui__label">Enter the Description</label>
              <TextArea
                name="description"
                id="description"
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter Description"
              />
              {touched.description && errors.description ? (
                <div id="errorMessage">{errors.description}</div>
              ) : null}
            </div>
            <TextInput
              name="link"
              id="link"
              label="Insert 3D View Link"
              placeholder="3D View Link"
              icon="linkify icon"
              type="url"
              value={values.link}
              touched={touched.link}
              error={errors.link}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <div id="login__field" className="field">
              <label className="ui__label">Upload Images</label>
              <Input
                name="uploadImage"
                type="file"
                accept="image/png, image/jpeg, image/jpg"
                onChange={e => {
                  setFieldValue('filesIMG', e.currentTarget.files)
                }}
                multiple
              ></Input>
            </div>
            <div id="login__field" className="field">
              <label className="ui__label">Upload Excel.csv or PDF Files</label>
              <Input
                name="upload"
                type="file"
                accept="application/pdf, text/csv"
                onChange={(event: any) => {
                  setFieldValue('files', event.currentTarget?.files)
                }}
                multiple
              ></Input>
            </div>
            <button
              id="btn__signup"
              className="ui fluid button"
              style={{ backgroundColor: '#ffc000', color: 'white' }}
              type="submit"
            >
              Edit Project
            </button>
          </Form>
          <div className="ui divider" />
        </div>
      </div>
    </div>
  )
}

export default EditProjectScreen
