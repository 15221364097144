import { ADD_ALERT, REMOVE_ALERT } from '../types'

export type Alert = {
  id: string
  message: string
}

export type AlertState = Alert[]

const initialState: AlertState = []

export default function AlertReducer(
  state = initialState,
  action: Action<string, Alert | string>
): AlertState {
  const { type, payload } = action

  switch (type) {
    case ADD_ALERT:
      return [...state, payload as Alert]
    case REMOVE_ALERT:
      const newState = state.filter(alert => alert.id !== payload)
      return newState
    default:
      return state
  }
}
