import { AnyAction, Dispatch } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { RootState } from '../store'
import { createAlert } from './alerts'
import api from '../../services/api'
import { History } from 'history'

import {
  FETCH_ELEMENTS_FAILURE,
  FETCH_ELEMENTS_SUCCESS,
  FETCH_PROJECTS_SUCCESS,
  PROJECT_ADDED,
  SINGLE_PROJECT_SUCCESS,
} from '../types'
import { ProjectElement } from '../../types/elements'

export const fetchProjects = () => async (
  dispatch: Dispatch<Action<string, IProject[] | string>>
) => {
  try {
    const projects = await api.getProjects()

    dispatch({
      type: FETCH_PROJECTS_SUCCESS,
      payload: projects,
    })
  } catch (err) {
    console.error(err)
  }
}

export const getSingleProject = (id: string) => async (
  dispatch: Dispatch<Action<string, IProject | string>>
) => {
  try {
    const project = await api.getSingleProject(id)
    dispatch({
      type: SINGLE_PROJECT_SUCCESS,
      payload: project,
    })
  } catch (err) {
    console.error(err)
  }
}

export const fetchElements = (
  ids: string[],
  page: number,
  size: number
) => async (dispatch: Dispatch<Action<string, ProjectElement[]>>) => {
  try {
    const data = await api.getElements(ids, page, size)
    dispatch({
      type: FETCH_ELEMENTS_SUCCESS,
      payload: data,
    })
  } catch (err) {
    dispatch({
      type: FETCH_ELEMENTS_FAILURE,
    })
  }
}

export const addProject = (
  formData: AddProjectFormData,
  history: History
): ThunkAction<void, RootState, unknown, AnyAction> => async dispatch => {
  try {
    const newProject = await api.addProject(formData)
    dispatch({
      type: PROJECT_ADDED,
      payload: newProject.statusText,
    })
    dispatch(createAlert('Project Added Successfully'))
    if (formData?.filesIMG) {
      const id = newProject.data._id
      const files = formData.filesIMG
      api.uploadFiles({ id, files })
    }
    if (formData?.files) {
      const id = newProject.data._id
      const files = formData.files
      api.uploadFiles({ id, files })
    }
    history.push('/projects')
  } catch (err) {
    dispatch({
      type: 'PROJECT_ADDED_FAILURE',
    })
    dispatch(createAlert('Project Already Exists'))
  }
}

export const deleteProject = (
  id: string,
  history: History
): ThunkAction<void, RootState, unknown, AnyAction> => async dispatch => {
  try {
    await api.deleteProject(id)
    dispatch(createAlert('Project Deleted Successfully'))
    await dispatch(fetchProjects())
    history.push('/projects')
  } catch (error) {
    console.error(error)
  }
}

export const editProject = (
  formData: EditProjectFormData,
  history: History
): ThunkAction<void, RootState, unknown, AnyAction> => async dispatch => {
  try {
    await api.editProject(formData)
    dispatch(createAlert('Project Edited Successfully'))
    if (formData?.filesIMG) {
      const id = formData._id
      const files = formData.filesIMG
      api.uploadFiles({ id, files })
    }
    if (formData?.files) {
      const id = formData._id
      const files = formData.files
      api.uploadFiles({ id, files })
    }
    history.push(`/projects`)
  } catch (error) {
    console.error(error)
    dispatch(createAlert('Unsuccsessful Project Edit'))
  }
}

export const deleteFile = (
  id: string,
  // files: string[] | undefined,
  imageName?: string | undefined,
  pdfName?: string | undefined
): ThunkAction<void, RootState, unknown, AnyAction> => async dispatch => {
  try {
    const imageArray: string[] = []
    const pdfArray: string[] = []
    if (imageName) {
      imageArray.push(imageName)
      await api.deleteFile(id, imageArray, undefined)
    }
    if (pdfName) {
      pdfArray.push(pdfName)
      await api.deleteFile(id, undefined, pdfArray)
    }
    dispatch(getSingleProject(id))
  } catch (error) {
    console.error(error)
  }
}

export const uploadPDF = (
  formData: uploadFilesFormData,
  history: History
): ThunkAction<void, RootState, unknown, AnyAction> => async dispatch => {
  try {
    await api.uploadFiles(formData)
    dispatch(createAlert('PDF Files Uploaded'))
    await dispatch(getSingleProject(formData.id))
  } catch (error) {
    console.error(error)
    dispatch(createAlert('Unsuccsessful Project Edit'))
  }
}
