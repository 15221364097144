import React from 'react'
import { Link } from 'react-router-dom'
import { useAppSelector } from '../../redux/hooks'

const NoMatch = () => {
  const user = useAppSelector(state => state.AuthReducer.user)

  return (
    <div>
      <div id="segment_404" className="ui placeholder teal segment">
        <div className="ui two column stackable center aligned grid">
          <div id="divider_404" className="ui vertical divider">
            <i className="window close icon" />
          </div>
          <div className="middle aligned row">
            <div className="column">
              <h1 id="error_code">404</h1>
              <h2 id="error_note">UH OH! You're lost.</h2>
            </div>
            <div className="column">
              <h4 id="error_message">
                The page you are looking for does not exist. How you got here is
                a mystery.
                <br />
                But you can click the button below to go back to the homepage.
              </h4>
              <Link
                to={user ? '/' : '/login'}
                id="btn--animated"
                className="ui animated button"
                tabIndex={0}
              >
                <div className="visible content">
                  {user ? 'Home Page' : 'Login'}
                </div>
                <div className="hidden content">
                  <i className="home icon" />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NoMatch
