import { AnyAction, Dispatch } from 'redux'
import { RootState } from '../store'
import { ThunkAction } from 'redux-thunk'
import { History } from 'history'

import api from '../../services/api'

import {
  FETCH_MATERIALS_SUCCESS,
  ADD_MATERIAL_SUCCESS,
  DELETE_MATERIAL_SUCCESS,
  FETCH_SINGLE_MATERIAL_SUCCESS,
} from '../types'
import { createAlert } from './alerts'

export const getMaterials = () => async (
  dispatch: Dispatch<Action<string, string>>
) => {
  try {
    const materials = await api.getMaterials()

    dispatch({
      type: FETCH_MATERIALS_SUCCESS,

      payload: materials,
    })
  } catch (err) {
    console.log(err)
  }
}

export const getSingleMaterial = (id: string) => {
  return async (dispatch: Dispatch<Action<string, string>>) => {
    try {
      const singleMaterial = await api.getSingleMaterial(id)
      dispatch({
        type: FETCH_SINGLE_MATERIAL_SUCCESS,
        payload: singleMaterial,
      })
      console.log('I am done')
    } catch (err) {
      console.log(err)
    }
  }
}

export const addMaterial = (
  formData: AddMaterialFormData,
  history: History
): ThunkAction<void, RootState, unknown, AnyAction> => async dispatch => {
  try {
    await api.addMaterial(formData)
    dispatch({
      type: ADD_MATERIAL_SUCCESS,
    })
    dispatch(createAlert('Material Created'))
    history.push('/materials/info')
  } catch (error) {
    console.error(error)
    dispatch(createAlert('Identical Material Already Exists'))
  }
}

export const deleteMaterial = (
  id: string,
  history: History
): ThunkAction<void, RootState, unknown, AnyAction> => async dispatch => {
  try {
    await api.deleteMaterial(id)
    dispatch({
      type: DELETE_MATERIAL_SUCCESS,
      payload: id,
    })
    dispatch(createAlert('Material Deleted Successfully'))
    history.push('/materials/info')
  } catch (error) {
    console.error(error)
  }
}

export const editMaterial = (
  formData: EditMaterialFormData,
  history: History
): ThunkAction<void, RootState, unknown, AnyAction> => async dispatch => {
  try {
    await api.editMaterial(formData)
    dispatch(createAlert('Material Edited Successfully'))
    history.push('/materials/info')
  } catch (error) {
    console.error(error)
    dispatch(createAlert('Unsuccessful Material Edit'))
  }
}
