import React from 'react'
import { useHistory } from 'react-router-dom'
import { Card, Button } from 'semantic-ui-react'
import { useAppSelector } from '../redux/hooks'

import defaultBackgroundImage from '../images/bannerLogo.png'

export type ProjectProps = {
  projectName: string
  location: string
  owner: string
  id: string
  elementsId: string[]
  description?: string
  date?: string
  descriptionImage: string
  externalDDCId?: number
}

const Project = ({
  projectName,
  id,
  location,
  owner,
  date,
  description,
  elementsId,
  descriptionImage,
}: ProjectProps): JSX.Element => {
  const history = useHistory()
  const role = useAppSelector(state => state.AuthReducer.user?.role)

  /* Functions for getting a list of Project Owners and tranvesring through the list to get a single project owner with a specific id */
  /*
  const projectOwners = useAppSelector(
    state => state.UsersReducer.allProjectOwners
  )

  
  const getProjectOwners = () => {
    const ownerFullName = projectOwners.map(element => {
      if (element._id === owner) {
        return `${element.profile.firstName} ${element.profile.lastName}`
      }
    })
    return ownerFullName
  }
  */

  if (role === 'ProjectOwner') {
    return (
      <Card color="teal">
        {descriptionImage ? (
          <img
            // cross-origin="cross-origin"
            src={`${process.env.REACT_APP_API_URL}/images/${descriptionImage}`}
            height={250}
            alt="uploadedDescriptionImage"
            className="descriptionImages"
          />
        ) : (
          <img
            src={defaultBackgroundImage}
            height={250}
            alt="defaultDescriptionImage"
            className="descriptionImages--default"
          />
        )}
        <Card.Content>
          <Card.Header>{projectName}</Card.Header>
          <Card.Meta>{location}</Card.Meta>
          <Card.Description>{description}</Card.Description>
        </Card.Content>
        <Card.Content extra textAlign="center">
          <Button
            className="cardButtons"
            onClick={() =>
              history.push(`/projects/${id}`, {
                projectName,
                location,
                owner,
                date,
                description,
                id,
                elementsId,
              })
            }
          >
            Read More
          </Button>
        </Card.Content>
      </Card>
    )
  }
  return (
    <Card color="teal">
      {descriptionImage ? (
        <img
          // cross-origin="cross-origin"
          src={`${process.env.REACT_APP_API_URL}/images/${descriptionImage}`}
          height={250}
          alt="uploadedDescriptionImage"
          className="descriptionImages"
        />
      ) : (
        <img
          src={defaultBackgroundImage}
          height={250}
          alt="defaultDescriptionImage"
          className="descriptionImages--default"
        />
      )}
      <Card.Content>
        <Card.Header>{projectName}</Card.Header>
        <Card.Meta>{location}</Card.Meta>
        <Card.Description>{description}</Card.Description>
      </Card.Content>
      <Card.Content extra textAlign="center">
        <Button
          className="cardButtons"
          onClick={() =>
            history.push(`/projects/${id}`, {
              projectName,
              location,
              owner,
              date,
              description,
              id,
              elementsId,
            })
          }
        >
          Read More
        </Button>
      </Card.Content>
    </Card>
  )
}

export default Project
