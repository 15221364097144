import { useFormik } from 'formik'
import * as yup from 'yup'
import { Container, Segment, Header, Button, Checkbox } from 'semantic-ui-react'
import TextInput from '../../components/TextInput'
import { addMaterial } from '../../redux/actions/materials'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  co2: yup.number().required('co2 number is required'),
  density: yup
    .number()
    .positive('It must be a positive number')
    .required('Density number is required'),
  isHazardous: yup.boolean().required('This field is required'),
  hasHazardousCoating: yup.boolean().required('This field is required'),
  technicalLifecycle: yup
    .number()
    .positive('It must be a positive number')
    .required('Technical Lifecycle number is required'),
})
const AddMaterialScreen = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const {
    handleSubmit,
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
  } = useFormik({
    initialValues: {
      name: '',
      co2: '',
      density: '',
      isHazardous: false,
      hasHazardousCoating: false,
      technicalLifecycle: '',
    },
    validationSchema,
    onSubmit: () => {
      dispatch(addMaterial(values, history))
    },
  })

  return (
    <div>
      <Container className="login__container">
        <div className="backButtonContainer"></div>
        <Segment raised color="teal">
          <Header className="signup__title" as="h3">
            Add Material
          </Header>
          <form onSubmit={handleSubmit} className="ui fluid form login__form">
            <TextInput
              name="name"
              id="name"
              label="Enter Name"
              placeholder="Name"
              icon="user icon"
              type="text"
              value={values.name}
              touched={touched.name}
              error={errors.name}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <TextInput
              name="co2"
              id="co2"
              label="Enter co2"
              placeholder="Name"
              icon="exclamation triangle icon"
              type="number"
              value={values.co2}
              touched={touched.co2}
              error={errors.co2}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <TextInput
              name="density"
              id="density"
              label="Enter Density"
              placeholder="Density"
              icon="circle icon"
              type="number"
              value={values.density}
              touched={touched.density}
              error={errors.density}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <div id="signup__field" className="field checkboxContainer">
              <div style={{ textAlign: 'center' }}>
                <label className="ui__label">Is it Hazardous?</label>
                <br />
                <Checkbox
                  className="checkbox"
                  name="isHazardous"
                  id="isHazardous"
                  checked={values.isHazardous}
                  label="Yes"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {errors?.isHazardous && touched.isHazardous ? (
                  <div id="errorMessage">{errors.isHazardous}</div>
                ) : null}
              </div>
              <div style={{ textAlign: 'center' }}>
                <label className="ui__label">
                  Does it have Hazardous Coating?
                </label>
                <br />
                <Checkbox
                  className="checkbox"
                  name="hasHazardousCoating"
                  id="hasHazardousCoating"
                  checked={values.hasHazardousCoating}
                  label="Yes"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {errors?.hasHazardousCoating && touched.hasHazardousCoating ? (
                  <div id="errorMessage">{errors.hasHazardousCoating}</div>
                ) : null}
              </div>
            </div>
            <TextInput
              name="technicalLifecycle"
              id="technicalLifecycle"
              label="Enter Technical Lifecycle"
              placeholder="Technical Lifecycle"
              icon="circle icon"
              type="number"
              value={values.technicalLifecycle}
              touched={touched.technicalLifecycle}
              error={errors.technicalLifecycle}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <Button
              className="btn__login"
              fluid={true}
              style={{ backgroundColor: '#ffc000', color: 'white' }}
              type="submit"
            >
              ADD MATERIAL
            </Button>
          </form>
        </Segment>
      </Container>
    </div>
  )
}

export default AddMaterialScreen
