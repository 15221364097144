import React from 'react'
import { Link, useHistory } from 'react-router-dom'

// redux
import { useDispatch } from 'react-redux'
import { logout } from '../redux/actions/auth'
import { useAppSelector } from '../redux/hooks'

/* Components */
import Hamburger from './Hamburger'
import bannerLogo from '../images/bannerLogo.png'

// Semantic
import { Dropdown } from 'semantic-ui-react'

// Styles
import '../styles/_header.scss'

const Header = () => {
  let history = useHistory()
  const role = useAppSelector(state => state.AuthReducer.user?.role)
  const id = useAppSelector(state => state.AuthReducer.user?._id)
  const dispatch = useDispatch()

  const handleLogout = () => {
    dispatch(logout())
    history.push('/login')
  }

  const renderHeader = (role?: string) => {
    switch (role) {
      case 'SuperAdmin':
        // @TODO Right now SuperAdmin is hard coded , this should be an enum once typescript is added
        return (
          <>
            <ul className="nav__links nav__pc">
              <li>
                <Link to="/">home</Link>
              </li>
              <li>
                <Link to="/about">about us</Link>
              </li>
              {id && (
                <li>
                  <Link to="/projects">project</Link>
                </li>
              )}
              <li>
                <a href="https://www.gtb-lab.com/publications">publicaties</a>
              </li>
              <li>
                <Link to="/contact">contact</Link>
              </li>
              <li>
                <Link to={`/user/superadmin/${id}`} id={'todo'}>
                  superadmin
                </Link>
              </li>
              {id && (
                <li id={'todo'}>
                  <Dropdown text={'settings'} floating>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        text="Settings Screen"
                        onClick={() => history.push('/settings')}
                      ></Dropdown.Item>
                      <Dropdown.Item
                        text="Materials Screen"
                        onClick={() => history.push('/materials/info')}
                      ></Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              )}
              <li id="toolkit">
                <Link to="/toolkit" style={{ color: 'red' }}>
                  reversible BIM toolkit
                </Link>
              </li>
            </ul>

            <button className="btn--logout nav__pc" onClick={handleLogout}>
              LOG OUT
            </button>
            <div className="nav__mobile">
              <Hamburger role={'SuperAdmin'} destination={'/user/superadmin'} />
            </div>
          </>
        )

      case undefined:
        return (
          <>
            <ul className="nav__links nav__pc">
              <li>
                <Link to="/">home</Link>
              </li>
              <li>
                <Link to="/about">about us</Link>
              </li>
              {id && (
                <li>
                  <Link to="/projects">project</Link>
                </li>
              )}
              <li>
                <a href="https://www.gtb-lab.com/publications">publicaties</a>
              </li>
              <li>
                <Link to="/contact">contact</Link>
              </li>
              {id && (
                <li>
                  <Link to={`/user/superadmin/${id}`} id={'todo'}>
                    my profile
                  </Link>
                </li>
              )}
              {id && (
                <li id={'todo'}>
                  <Dropdown text={'settings'} floating>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        text="Settings Screen"
                        onClick={() => history.push('/settings')}
                      ></Dropdown.Item>
                      <Dropdown.Item
                        text="Materials Screen"
                        onClick={() => history.push('/materials/info')}
                      ></Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              )}
              <li id="toolkit">
                <Link to="/toolkit" style={{ color: 'red' }}>
                  reversible BIM toolkit
                </Link>
              </li>
            </ul>

            <Link to="/login">
              <button className="btn--logout nav__pc">LOG IN</button>
            </Link>

            <div className="nav__mobile">
              <Hamburger role={'SuperAdmin'} destination={'/user/superadmin'} />
            </div>
          </>
        )

      default:
        return (
          <>
            <ul className="nav__links nav__pc">
              <li>
                <Link to="/">home</Link>
              </li>
              <li>
                <Link to="/about">about us</Link>
              </li>
              {id && (
                <li>
                  <Link to="/projects">project</Link>
                </li>
              )}
              <li>
                <a href="https://www.gtb-lab.com/publications">publicaties</a>
              </li>
              <li>
                <Link to="/contact">contact</Link>
              </li>
              {id && (
                <li>
                  <Link to={`/user/superadmin/${id}`} id={'todo'}>
                    my profile
                  </Link>
                </li>
              )}
              {id && (
                <li id={'todo'}>
                  <Dropdown text={'settings'} floating>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        text="Settings Screen"
                        onClick={() => history.push('/settings')}
                      ></Dropdown.Item>
                      <Dropdown.Item
                        text="Materials Screen"
                        onClick={() => history.push('/materials/info')}
                      ></Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              )}
              <li id="toolkit">
                <Link to="/toolkit" style={{ color: 'red' }}>
                  reversible BIM toolkit
                </Link>
              </li>
            </ul>

            <button className="btn--logout nav__pc" onClick={handleLogout}>
              LOG OUT
            </button>

            {id && (
              <div className="nav__mobile">
                <Hamburger role={'My Profile'} destination={'/user'} />
              </div>
            )}
          </>
        )
    }
  }
  return (
    <div>
      <div className="header__image">
        <img
          src={bannerLogo}
          className="header__image--logo"
          alt="bannerLogo"
        ></img>
      </div>
      <div className="header">
        <div className="header__container">{renderHeader(role)}</div>
      </div>
    </div>
  )
}
export default Header
