import React, { useEffect } from 'react'
import { Route, Switch, BrowserRouter } from 'react-router-dom'

/* Style */
import './styles/app.css'
import './styles/style.scss'

/* Components */
import {
  CreateUser,
  Login,
  NoMatch,
  PrivateRoute,
  Profilepage,
  SuperAdmin,
  HomePage,
  ProjectScreen,
  AddProject,
  EditProjectScreen,
  EditProfile,
  BIMScreen,
  SettingsScreen,
  MaterialsScreen,
  AddMaterialScreen,
  EditMaterialScreen,
  Dashboard,
  TestHomepage,
  Fallback,
  RBDInstruments,
  RBDPrincipleGuidelines,
  CircularBuildingDesign,
  AboutUs,
  Contact,
} from './screens'
import { Layout, Snackbar } from './components'
import { useAppSelector } from './redux/hooks'
import { useDispatch } from 'react-redux'
import { getSystemUser } from './redux/actions/auth'

function App() {
  const user = useAppSelector(state => state.AuthReducer.user)
  const alerts = useAppSelector(state => state.AlertsReducer)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!user) {
      dispatch(getSystemUser())
    }
  }, [dispatch, user])

  return (
    <BrowserRouter>
      <Layout>
        <div className="site-content">
          <Switch>
            <Route path="/fallback" exact component={Fallback} />
            <Route path="/testhomepage" exact component={TestHomepage} />
            <Route path="/" exact component={Dashboard} />
            <Route path="/about" exact component={AboutUs} />
            <Route path="/contact" exact component={Contact} />
            <PrivateRoute
              path="/RBDPrincipleGuidelines"
              component={RBDPrincipleGuidelines}
            />
            <PrivateRoute
              path="/CircularBuildingDesign"
              component={CircularBuildingDesign}
            />
            <PrivateRoute path="/RBDInstruments" component={RBDInstruments} />
            <PrivateRoute path="/user/superadmin/:id" component={SuperAdmin} />
            <PrivateRoute path="/user/edit/:id" component={EditProfile} />
            <PrivateRoute path="/user" component={Profilepage} />
            <Route path="/login" component={Login} />
            <Route path="/create" component={CreateUser} />
            <PrivateRoute path="/toolkit" component={BIMScreen} />
            <PrivateRoute path="/settings" component={SettingsScreen} />
            <PrivateRoute path="/materials/info" component={MaterialsScreen} />
            <PrivateRoute path="/materials/add" component={AddMaterialScreen} />
            <PrivateRoute
              path="/materials/:id"
              component={EditMaterialScreen}
            />
            <PrivateRoute path="/projects/add/:id" component={AddProject} />
            <PrivateRoute
              path="/project/edit/:id"
              component={EditProjectScreen}
            />
            <PrivateRoute path="/projects/:id" component={ProjectScreen} />
            <PrivateRoute path="/projects" component={HomePage} />
            <Route path="*" component={NoMatch} />
          </Switch>
          {alerts.map(alert => (
            <Snackbar content={alert.message} key={alert.id} id={alert.id} />
          ))}
        </div>
      </Layout>
    </BrowserRouter>
  )
}

export default App
