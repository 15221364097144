import {
  FETCH_MATERIALS_SUCCESS,
  ADD_MATERIAL_SUCCESS,
  DELETE_MATERIAL_SUCCESS,
  FETCH_SINGLE_MATERIAL_SUCCESS,
} from '../types'

export type MaterialState = {
  materials: IMaterial[]
  singleMaterial: IMaterial | any
  message: string
}

const initialState: MaterialState = {
  materials: [],
  singleMaterial: {
    _id: '',
    name: '',
    co2: 0,
    density: 0,
    isHazardous: true,
    hasHazardousCoating: true,
    technicalLifecycle: 0,
  },
  message: '',
}

export const MaterialsReducer = (
  state = initialState,
  action: Action<string, IMaterial[]>
) => {
  const { type, payload } = action

  switch (type) {
    case FETCH_MATERIALS_SUCCESS:
      return {
        ...state,
        materials: payload as IMaterial[],

        message: 'Fetch Materials Success',
      }
    case ADD_MATERIAL_SUCCESS:
      return {
        ...state,
        message: 'Material Added',
      }
    case DELETE_MATERIAL_SUCCESS:
      const newMaterials = state.materials.filter(
        material => material._id !== payload
      )
      return {
        ...state,
        message: 'Material Deleted',
        materials: newMaterials,
      }
    case FETCH_SINGLE_MATERIAL_SUCCESS:
      return {
        ...state,
        singleMaterial: payload,
      }
    default:
      return state
  }
}
