import React, { ChangeEventHandler, FocusEventHandler } from 'react'
import { FormikErrors, FormikTouched } from 'formik'

type TextInputProps = {
  label: string
  name: string
  id: string
  handleChange: ChangeEventHandler<HTMLInputElement>
  handleBlur: FocusEventHandler<HTMLInputElement>
  icon: string
  value: string
  type: string
  placeholder: string
  touched?: FormikTouched<boolean>
  error?: FormikErrors<string>
}

const TextInput = ({
  label,
  name,
  id,
  handleChange,
  handleBlur,
  value,
  type,
  placeholder,
  icon,
  touched,
  error,
}: TextInputProps) => {
  return (
    <div>
      <div className="field login__field">
        <label className="ui__label">{label}</label>
        <div className="ui icon input">
          <input
            name={name}
            id={id}
            onChange={handleChange}
            onBlur={handleBlur}
            value={value}
            type={type}
            placeholder={placeholder}
          ></input>
          <i className={icon}></i>
        </div>
        {touched && error ? <div id="errorMessage">{error}</div> : null}
      </div>
    </div>
  )
}

export default TextInput
