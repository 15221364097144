import {
  FETCH_USER_FAILURE,
  FETCH_USER_SUCCESS,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  LOGOUT,
  SIGNUP_FAILED,
  SIGNUP_SUCCESS,
  SIGNUP_DONE,
  PROJECT_ADDED,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,
} from '../types'

export type AuthState = {
  message: string
  user: IUser | null
}

const initialState: AuthState = {
  message: '',
  user: null,
}

export default function AuthReducer(
  state = initialState,
  action: Action<string, IUser | string>
): AuthState {
  const { type, payload } = action
  switch (type) {
    case LOGIN_SUCCESS:
    case SIGNUP_DONE:
      return {
        ...state,
        message: payload as string,
      }
    case LOGIN_FAILED:
    case SIGNUP_SUCCESS:
    case SIGNUP_FAILED:
      return {
        ...state,
        message: payload as string,
      }
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        user: payload as IUser,
      }
    case FETCH_USER_FAILURE:
      return {
        ...state,
      }
    case PROJECT_ADDED:
      return {
        ...state,
        message: payload as string,
      }
    case 'PROJECT_ADDED_FAILURE':
      return {
        ...state,
        message: payload as string,
      }
    case EDIT_USER_SUCCESS:
      return {
        ...state,

        message: payload as string,
      }

    case EDIT_USER_FAILURE:
      return {
        ...state,
        message: payload as string,
      }
    case LOGOUT:
      return {
        message: 'Successful Logout',
        user: null,
      }
    default:
      return state
  }
}
