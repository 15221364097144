import React from 'react'
import { Header, Icon, Segment } from 'semantic-ui-react'

// Style
import '../../styles/_fallback.scss'

const Fallback = () => {
  return (
    <Segment color="teal" className="segment-fallback" placeholder>
      <Header icon>
        <Icon name="computer" />
        Work in Progress
      </Header>
    </Segment>
  )
}

export default Fallback
