import React from 'react'
import { Button, Header } from 'semantic-ui-react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { deleteMaterial } from '../redux/actions/materials'
import { deleteProject } from '../redux/actions/projects'
import { deleteUser } from '../redux/actions/users'
import { deleteFile } from '../redux/actions/projects'
import { useAppSelector } from '../redux/hooks'

type propsTypes = {
  content?: string
  isOpen: boolean
  closeModal: any
  elementID: string
  screen: string
  fileName?: string
  files?: string[]
}
const Modal = ({
  content,
  isOpen,
  closeModal,
  elementID,
  screen,
  fileName,
  files,
}: propsTypes) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const pageUserID = useAppSelector(state => state.AuthReducer.user?._id)

  if (isOpen === true) {
    if (screen === 'material') {
      return (
        <div>
          <div className="modal__background" onClick={() => closeModal()}></div>
          <div className="modal__content">
            <span className="modal__X" onClick={() => closeModal()}>
              X
            </span>
            <Header as="h4" style={{ backgroundColor: 'white' }}>
              {`Do you want to delete ${content}?`}
            </Header>
            <div className="modal__buttonContainer">
              <Button
                className="modalButton--Yes"
                color="red"
                onClick={() => {
                  dispatch(deleteMaterial(elementID, history))
                  closeModal()
                }}
              >
                Yes
              </Button>
              <Button
                className="modalButton--No"
                style={{ backgroundColor: '#ffc000', color: 'white' }}
                onClick={() => closeModal()}
              >
                No
              </Button>
            </div>
          </div>
        </div>
      )
    } else if (screen === 'superadmin') {
      return (
        <div>
          <div className="modal__background" onClick={() => closeModal()}></div>
          <div className="modal__content">
            <span className="modal__X" onClick={() => closeModal()}>
              X
            </span>
            <Header as="h4" style={{ backgroundColor: 'white' }}>
              {`Do you want to delete ${content}?`}
            </Header>
            <div className="modal__buttonContainer">
              <Button
                className="modalButton--Yes"
                color="red"
                onClick={() => {
                  dispatch(deleteUser(elementID, pageUserID, history))
                  closeModal()
                }}
              >
                Yes
              </Button>
              <Button
                className="modalButton--No"
                style={{ backgroundColor: '#ffc000', color: 'white' }}
                onClick={() => closeModal()}
              >
                No
              </Button>
            </div>
          </div>
        </div>
      )
    } else if (screen === 'singleProjectGallery') {
      return (
        <div>
          <div className="modal__background" onClick={() => closeModal()}></div>
          <div className="modal__content">
            <span className="modal__X" onClick={() => closeModal()}>
              X
            </span>
            <Header as="h4" style={{ backgroundColor: 'white' }}>
              {`Do you want to delete ${content}?`}
            </Header>
            <div className="modal__buttonContainer">
              <Button
                className="modalButton--Yes"
                color="red"
                onClick={() => {
                  dispatch(deleteFile(elementID, fileName))
                  closeModal()
                }}
              >
                Yes
              </Button>
              <Button
                className="modalButton--No"
                style={{ backgroundColor: '#ffc000', color: 'white' }}
                onClick={() => closeModal()}
              >
                No
              </Button>
            </div>
          </div>
        </div>
      )
    } else if (screen === 'singleProjectPDF') {
      return (
        <div>
          <div className="modal__background" onClick={() => closeModal()}></div>
          <div className="modal__content">
            <span className="modal__X" onClick={() => closeModal()}>
              X
            </span>
            <Header as="h4" style={{ backgroundColor: 'white' }}>
              {`Do you want to delete ${content}?`}
            </Header>
            <div className="modal__buttonContainer">
              <Button
                className="modalButton--Yes"
                color="red"
                onClick={() => {
                  dispatch(deleteFile(elementID, undefined, fileName))
                  closeModal()
                }}
              >
                Yes
              </Button>
              <Button
                className="modalButton--No"
                style={{ backgroundColor: '#ffc000', color: 'white' }}
                onClick={() => closeModal()}
              >
                No
              </Button>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div className="modal__background" onClick={() => closeModal()}></div>
          <div className="modal__content">
            <span className="modal__X" onClick={() => closeModal()}>
              X
            </span>
            <Header as="h4" style={{ backgroundColor: 'white' }}>
              {`Do you want to delete ${content}?`}
            </Header>
            <div className="modal__buttonContainer">
              <Button
                className="modalButton--Yes"
                color="red"
                onClick={() => {
                  dispatch(deleteProject(elementID, history))
                  closeModal()
                  history.goBack()
                }}
              >
                Yes
              </Button>
              <Button
                className="modalButton--No"
                style={{ backgroundColor: '#ffc000', color: 'white' }}
                onClick={() => closeModal()}
              >
                No
              </Button>
            </div>
          </div>
        </div>
      )
    }
  } else {
    return null
  }
}

export default Modal
