import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAppSelector } from '../../redux/hooks'

type PrivateRouteProps = {
  component: React.FC
  path: string
}

const PrivateRoute = ({
  component: Component,
  path,
  ...rest
}: PrivateRouteProps) => {
  const user = useAppSelector(state => state.AuthReducer.user)
  return (
    <Route
      {...rest}
      render={() =>
        !user ? <Redirect to="/login" /> : <Component {...rest} />
      }
    />
  )
}

export default PrivateRoute
