import React from 'react'
import { Card, Image, Button } from 'semantic-ui-react'

/* Styles */
import '../../styles/_dashboard.scss'

const TestHomepage = () => {
  return (
    <div
      style={{
        width: '80%',
        margin: '0 auto',
      }}
    >
      <Card.Group centered stackable doubling itemsPerRow="4">
        <Card color="teal">
          <Image
            src="https://react.semantic-ui.com/images/avatar/large/daniel.jpg"
            wrapped
            ui={false}
          />
          <Card.Content>
            <Card.Header>Header Example (props.header)</Card.Header>
            <Card.Meta>Subheader Example (props.subheader)</Card.Meta>
            <Card.Description>
              Description Example (props.description)
            </Card.Description>
          </Card.Content>
          <Card.Content extra textAlign="center">
            <Button className="cardButtons">Read More</Button>
          </Card.Content>
        </Card>
        <Card color="teal">
          <Image
            src="https://react.semantic-ui.com/images/avatar/large/daniel.jpg"
            wrapped
            ui={false}
          />
          <Card.Content>
            <Card.Header>Header Example (props.header)</Card.Header>
            <Card.Meta>Subheader Example (props.subheader)</Card.Meta>
            <Card.Description>
              Description Example (props.description)
            </Card.Description>
          </Card.Content>
          <Card.Content extra textAlign="center">
            <Button className="cardButtons">Read More</Button>
          </Card.Content>
        </Card>
        <Card color="teal">
          <Image
            src="https://react.semantic-ui.com/images/avatar/large/daniel.jpg"
            wrapped
            ui={false}
          />
          <Card.Content>
            <Card.Header>Header Example (props.header)</Card.Header>
            <Card.Meta>Subheader Example (props.subheader)</Card.Meta>
            <Card.Description>
              Description Example (props.description)
            </Card.Description>
          </Card.Content>
          <Card.Content extra textAlign="center">
            <Button className="cardButtons">Read More</Button>
          </Card.Content>
        </Card>
        <Card color="teal">
          <Image
            src="https://react.semantic-ui.com/images/avatar/large/daniel.jpg"
            wrapped
            ui={false}
          />
          <Card.Content>
            <Card.Header>Header Example (props.header)</Card.Header>
            <Card.Meta>Subheader Example (props.subheader)</Card.Meta>
            <Card.Description>
              Description Example (props.description)
            </Card.Description>
          </Card.Content>
          <Card.Content extra textAlign="center">
            <Button className="cardButtons">Read More</Button>
          </Card.Content>
        </Card>
        <Card color="teal">
          <Image
            src="https://react.semantic-ui.com/images/avatar/large/daniel.jpg"
            wrapped
            ui={false}
          />
          <Card.Content>
            <Card.Header>Header Example (props.header)</Card.Header>
            <Card.Meta>Subheader Example (props.subheader)</Card.Meta>
            <Card.Description>
              Description Example (props.description)
            </Card.Description>
          </Card.Content>
          <Card.Content extra textAlign="center">
            <Button className="cardButtons">Read More</Button>
          </Card.Content>
        </Card>
        <Card color="teal">
          <Image
            src="https://react.semantic-ui.com/images/avatar/large/daniel.jpg"
            wrapped
            ui={false}
          />
          <Card.Content>
            <Card.Header>Header Example (props.header)</Card.Header>
            <Card.Meta>Subheader Example (props.subheader)</Card.Meta>
            <Card.Description>
              Description Example (props.description)
            </Card.Description>
          </Card.Content>
          <Card.Content extra textAlign="center">
            <Button className="cardButtons">Read More</Button>
          </Card.Content>
        </Card>
      </Card.Group>
    </div>
  )
}
export default TestHomepage
