import { useEffect, useState } from 'react'
import { useAppSelector } from '../../redux/hooks'
import Modal from '../../components/Modal'
import { useHistory, Link } from 'react-router-dom'

import {
  Container,
  Segment,
  Header,
  Table,
  Icon,
  Checkbox,
} from 'semantic-ui-react'

import { useDispatch } from 'react-redux'

import { getMaterials } from '../../redux/actions/materials'
import AddButton from '../../components/AddButton'

const MaterialsScreen = () => {
  const [show, setShow] = useState(false)
  const [materialID, setMaterialID] = useState('')
  const [materialName, setMaterialName] = useState('')
  const history = useHistory()

  const materials = useAppSelector(state => {
    return state.MaterialsReducer.materials
  })
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getMaterials())
  }, [dispatch])

  const openModal = () => {
    setShow(true)
  }

  const closeModal = () => {
    setShow(false)
  }

  const materialInfo = (materialID: string, name: string) => {
    setMaterialID(materialID)
    setMaterialName(name)
  }

  return (
    <div>
      <Container textAlign="right" style={{ width: '80%' }}>
        {materialID && (
          <Modal
            content={materialName}
            isOpen={show}
            closeModal={closeModal}
            elementID={materialID}
            screen="material"
          />
        )}

        <AddButton content="Add Material" route="/materials/add" />
      </Container>
      <div className="materialsContainer">
        <Header
          as="h1"
          style={{ backgroundColor: 'white' }}
          textAlign="center"
          attached="top"
        >
          Materials
          <Header.Subheader style={{ backgroundColor: 'white' }}>
            Information about Materials
          </Header.Subheader>
        </Header>

        <Segment attached>
          <Table celled textAlign="center">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Material ID</Table.HeaderCell>
                <Table.HeaderCell>Material Name</Table.HeaderCell>
                <Table.HeaderCell>co2</Table.HeaderCell>
                <Table.HeaderCell>Density</Table.HeaderCell>
                <Table.HeaderCell>Hazardous</Table.HeaderCell>
                <Table.HeaderCell>Hazardous Coating</Table.HeaderCell>
                <Table.HeaderCell>Technical Lifecycle</Table.HeaderCell>
                <Table.HeaderCell width={2} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {materials.map(material => {
                return (
                  <Table.Row key={material._id}>
                    <Table.Cell>{material._id}</Table.Cell>
                    <Table.Cell>{material.name}</Table.Cell>
                    <Table.Cell>{material.co2}</Table.Cell>
                    <Table.Cell>{material.density}</Table.Cell>
                    <Table.Cell>
                      <Checkbox checked={material.isHazardous} />
                    </Table.Cell>

                    <Table.Cell>
                      <Checkbox checked={material.hasHazardousCoating} />
                    </Table.Cell>
                    <Table.Cell>{material.technicalLifecycle}</Table.Cell>
                    <Table.Cell>
                      <Link
                        to={{
                          pathname: `/materials/${material._id}`,
                          state: {
                            materialName: material.name,
                            materialCO2: material.co2,
                            materialDensity: material.density,
                            materialHazardous: material.isHazardous,
                            materialHazardousCoating:
                              material.hasHazardousCoating,
                            materialTechnicalLifecycle:
                              material.technicalLifecycle,
                          },
                        }}
                      >
                        <Icon
                          name="edit"
                          link
                          size="large"
                          color="yellow"
                          style={{
                            margin: '10px 10px',
                            title: 'Edit Material',
                          }}
                        ></Icon>
                      </Link>
                      <Icon
                        name="delete"
                        link
                        size="large"
                        color="red"
                        style={{
                          margin: '10px 10px',
                          title: 'Delete Material',
                        }}
                        onClick={() => {
                          materialInfo(material._id, material.name)
                          openModal()
                        }}
                      ></Icon>
                    </Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>
        </Segment>
      </div>
    </div>
  )
}

export default MaterialsScreen
