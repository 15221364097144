import React from 'react'
import { Button, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

type propsType = {
  route: string
}

const UploadButton = ({ route }: propsType): JSX.Element => {
  return (
    <Link to={`${route}`}>
      <Button
        animated
        className="uploadButton"
        size="big"
        style={{
          backgroundColor: '#ffc000',
          color: 'white',
          marginTop: '15px',
        }}
      >
        <Button.Content visible>Upload</Button.Content>
        <Button.Content hidden>
          <Icon name="upload" />
        </Button.Content>
      </Button>
    </Link>
  )
}

export default UploadButton
