export const LOGIN_FAILED: string = 'LOGIN_FAILED'
export const LOGIN_SUCCESS: string = 'LOGIN_SUCCESS'
export const LOGOUT: string = 'LOGOUT'
export const SIGNUP_SUCCESS: string = 'SIGNUP_SUCCESS'
export const SIGNUP_FAILED: string = 'SIGNUP_FAILED'
export const SIGNUP_DONE: string = 'SIGNUP_DONE'
export const FETCH_USER_SUCCESS: string = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE: string = 'FETCH_USER_FAILURE'
export const FETCH_PROJECTS_SUCCESS: string = 'FETCH_PROJECTS_SUCCESS'
export const FETCH_PROJECTS_FAILURE: string = 'FETCH_PROJECTS_FAILURE'
export const SINGLE_PROJECT_SUCCESS: string = 'SINGLE_PROJECT_SUCCESS'
export const FETCH_ELEMENTS_SUCCESS: string = 'FETCH_ELEMENTS_SUCCESS'
export const FETCH_ELEMENTS_FAILURE: string = 'FETCH_ELEMENTS_FAILURE'
export const PROJECT_ADDED: string = 'PROJECT_ADDED'
export const EDIT_USER_SUCCESS: string = 'PROJECT_ADDED'
export const EDIT_USER_FAILURE: string = 'PROJECT_ADDED'
export const FETCH_MATERIALS_SUCCESS: string = 'FETCH_MATERIALS_SUCCESS'
export const FETCH_SINGLE_MATERIAL_SUCCESS: string =
  'FETCH_SINGLE_MATERIAL_SUCCESS'
export const ADD_MATERIAL_SUCCESS: string = 'ADD_MATERIAL_SUCCESS'
export const DELETE_MATERIAL_SUCCESS: string = 'DELETE_MATERIAL_SUCCESS'
export const ADD_ALERT: string = 'ADD_ALERT'
export const REMOVE_ALERT: string = 'REMOVE_ALERT'
