import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useAppSelector } from '../../redux/hooks'
import {
  Container,
  Segment,
  Header,
  Table,
  Icon,
  Button,
} from 'semantic-ui-react'
import { getAllUsers } from '../../redux/actions/users'
import { useDispatch } from 'react-redux'
import Modal from '../../components/Modal'

const SuperAdmin = (props: any) => {
  const user = useAppSelector(state => state.AuthReducer.user)
  const users = useAppSelector(state => state.UsersReducer.allUsers)
  const dispatch = useDispatch()
  const { id } = useParams<{ id: string }>()
  const [show, setShow] = useState(false)

  const [userName, setUserName] = useState('')
  const [userID, setUserID] = useState('')
  useEffect(() => {
    dispatch(getAllUsers())
  }, [dispatch])

  const openModal = () => {
    setShow(true)
  }

  const closeModal = () => {
    setShow(false)
  }

  const usersMappedArray = () => {
    const mappedUsers = users.map(user => {
      return (
        <Table.Row key={user?._id}>
          <Table.Cell>{user?.email}</Table.Cell>
          <Table.Cell>{user?.role}</Table.Cell>
          <Table.Cell>
            <Link to={`/user/edit/${user?._id}`}>
              <Icon
                name="edit"
                link
                size="large"
                color="yellow"
                style={{
                  margin: '10px 10px',
                  title: 'Edit Material',
                }}
              ></Icon>
            </Link>
            <Icon
              name="delete"
              link
              size="large"
              color="red"
              style={{
                margin: '10px 10px',
                title: 'Delete User',
              }}
              onClick={() => {
                openModal()
                setUserName(user?.email)
                setUserID(user?._id)
              }}
            />
          </Table.Cell>
        </Table.Row>
      )
    })

    return mappedUsers
  }
  return (
    <Container
      style={{ backgroundColor: 'white' }}
      className="profile--container"
    >
      <Modal
        content={userName}
        isOpen={show}
        closeModal={closeModal}
        elementID={userID}
        screen="superadmin"
      />
      <Header
        as="h1"
        style={{ backgroundColor: 'white' }}
        textAlign="center"
        attached="top"
      >
        User Information
        <Header.Subheader style={{ backgroundColor: 'white' }}>
          Edit Profile and See Profile Information
        </Header.Subheader>
      </Header>
      <Segment attached>
        <Table compatct celled definitioon stackable textAlign="center">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Role</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>{user?.email}</Table.Cell>
              <Table.Cell>{user?.role}</Table.Cell>
              <Table.Cell>
                <Link to={`/user/edit/${id}`}>
                  <Icon
                    name="edit"
                    link
                    size="large"
                    color="yellow"
                    style={{
                      margin: '10px 10px',
                      title: 'Edit User',
                    }}
                  />
                </Link>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
          <Table.Footer fullWidth>
            <Table.Row textAlign="center">
              <Table.HeaderCell colSpan="3">
                <Link to="/create/user">
                  <Button
                    size="big"
                    style={{
                      backgroundColor: '#ffc000',
                      color: 'white',
                      margin: '15px 0',
                    }}
                    animated="vertical"
                  >
                    <Button.Content visible>Add User</Button.Content>
                    <Button.Content hidden>
                      <Icon name="user plus" />
                    </Button.Content>
                  </Button>
                </Link>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>

        {/* List of all users Table */}
        <Header as="h2" style={{ backgroundColor: 'white' }} textAlign="center">
          List of all Users
          <Header.Subheader style={{ backgroundColor: 'white' }}>
            User's Email and Role Information
          </Header.Subheader>
        </Header>
        <Table compatct celled definitioon stackable textAlign="center">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Role</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>{usersMappedArray()}</Table.Body>
        </Table>
      </Segment>
    </Container>
  )
}

export default SuperAdmin
