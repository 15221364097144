import axios, { AxiosResponse } from 'axios'
import { ProjectProps } from '../components/Project'
import {
  CreateDDCProjectProps,
  DDCProjectProps,
  Profile,
} from '../types/ddcProject'
import { ProjectElement } from '../types/elements'

interface AuthResponse {
  token: string
  id: number
}

const DDC_API_URL = 'https://ddcplatform.list.lu:4000'

let token = ''

let userId = 0

const config = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
}

const axiosInstance = axios.create(config)

export const createDDCAuthToken = async (
  login: string,
  password: string
): Promise<string> => {
  try {
    // Check if a profile with the given credentials exists
    const authResponse: AxiosResponse<AuthResponse> = await axiosInstance.post(
      `${DDC_API_URL}/auth/login`,
      {
        login,
        password,
      }
    )
    // userId = authResponse.data.id
    console.log(authResponse)
    debugger
    // If authentication is successful, return the auth token
    return authResponse.data.token
  } catch (error) {
    // If authentication fails, create a new profile
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      const createProfileResponse: AxiosResponse<AuthResponse> = await axiosInstance.post(
        `${DDC_API_URL}/users`,
        {
          login,
          password,
          name: 'GTLAB',
          firstName: 'GTL',
          email: 'semir.sahman@waltercode.com',
          active: true,
          blocked: false,
        } as Profile
      )

      // Return the auth token from the newly created profile
      return createProfileResponse.data.token
    }

    // Handle other errors
    throw error
  }
}

export const checkIfProjectExistOnDDC = async (
  id: number,
  token: string
): Promise<boolean> => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    const axiosInstance = axios.create(config)

    const response: AxiosResponse<DDCProjectProps> = await axiosInstance.get(
      `${DDC_API_URL}/projects/${id}`
    )

    return response.status === 200 ? true : false
  } catch (error) {
    throw error
  }
}

export const createProjectOnDDC = async (
  project: ProjectProps,
  token: string
): Promise<void> => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    const axiosInstance = axios.create(config)

    const {
      projectName,
      location,
      owner,
      description,
      date,
      externalDDCId,
    } = project

    const projectToCreate: CreateDDCProjectProps = {
      fullName: projectName,
      projectType: 'renovation',
      shortName: 'MIN',
      description:
        'The Maison de l’Innovation will host research activities that require high performance software with important operational potential in the fields of information, communication and health.',
      deconstructionStart: '2032-08-01',
      footprint: 1000,
      contactInfo: 'Daniel Düsentrieb',
      owner: {
        // id: 27,
        login: 'johndoe',
        password: '*********',
        name: 'Doe',
        firstName: 'John',
        email: 'john.doe@list.lu',
        active: true,
        blocked: true,
        blockingReason: 'User is no longer part of the organisation',
        // parallelLogins: true,
      },
      fullAddress: {
        addressLine1: '5, Avenue des Hauts-Fourneaux',
        addressLine2: 'Bâtiment B',
        city: 'Esch-sur-Alzette',
        stateOrProvince: 'Canton Esch',
        zipOrPostalCode: 'L-4362',
        country: 'Luxembourg',
      },
    }
    await axiosInstance.post(`${DDC_API_URL}/projects`, projectToCreate)
  } catch (error) {
    // If forbiden, assign a new role with privileges to profile
    if (axios.isAxiosError(error) && error.response?.status === 403) {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const axiosInstance = axios.create(config)
      const response: AxiosResponse<AuthResponse> = await axiosInstance.get(
        `${DDC_API_URL}/users/currentUser`
      )
      userId = response.data.id
      await axiosInstance.put(`${DDC_API_URL}/roles/assignRole`, {
        roleId: 5,
        userId: userId,
      })
      debugger
      await createProjectOnDDC(project, token)
    }

    // Handle other errors
    throw error
  }
}

export const updateProjectOnDDC = async (
  project: ProjectProps,
  token: string
): Promise<void> => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    const axiosInstance = axios.create(config)

    const {
      projectName,
      location,
      owner,
      description,
      date,
      externalDDCId,
    } = project

    await axiosInstance.patch(`${DDC_API_URL}/projects/${externalDDCId}`, {
      fullName: projectName,
    })
  } catch (error) {
    throw error
  }
}

export const syncProjectElementsAndMaterialsOnDDC = async (
  externalDDCId: number,
  elements: ProjectElement
): Promise<void> => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    // TO DO

    // const axiosInstance = axios.create(config)

    // const {
    //   projectName,
    //   location,
    //   owner,
    //   description,
    //   date,
    //   externalDDCId,
    // } = project
    // debugger
    // await axiosInstance.post(
    //   `${DDC_API_URL}/projects/${externalDDCId}/inventory`,
    //   {
    //     fullName: projectName,
    //     projectType: 'renovation',
    //     shortName: 'MIN',
    //     description:
    //       'The Maison de l’Innovation will host research activities that require high performance software with important operational potential in the fields of information, communication and health.',
    //     deconstructionStart: '2032-08-01',
    //     footprint: 1000,
    //     contactInfo: 'Daniel Düsentrieb',
    //     owner: {
    //       id: 27,
    //       login: 'johndoe',
    //       password: '*********',
    //       name: 'Doe',
    //       firstName: 'John',
    //       email: 'john.doe@list.lu',
    //       active: true,
    //       blocked: true,
    //       blockingReason: 'User is no longer part of the organisation',
    //       parallelLogins: true,
    //     },
    //     fullAddress: {
    //       addressLine1: '5, Avenue des Hauts-Fourneaux',
    //       addressLine2: 'Bâtiment B',
    //       city: 'Esch-sur-Alzette',
    //       stateOrProvince: 'Canton Esch',
    //       zipOrPostalCode: 'L-4362',
    //       country: 'Luxembourg',
    //     },
    //   }
    // )
  } catch (error) {
    throw error
  }
}

export const syncProjectToDDC = async (
  project: ProjectProps
): Promise<void> => {
  try {
    token = await createDDCAuthToken(
      process.env.DDC_LOGIN || 'semagtlab',
      process.env.DDC_PASSWORD || '@semagtlab'
    )
    const { externalDDCId } = project
    debugger
    if (
      externalDDCId &&
      (await checkIfProjectExistOnDDC(externalDDCId, token))
    ) {
      //PATCH
      await updateProjectOnDDC(project, token)
    } else {
      //POST
      await createProjectOnDDC(project, token)
    }
  } catch (error) {
    throw error
  }
}
