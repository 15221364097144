import React from 'react'
import { Link } from 'react-router-dom'

/* Images */
import logo from '../images/4dArchitectsLogo.png'
import EULogo from '../images/EULogo.png'

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-content">
        <div>
          <h4 style={{ marginBottom: '10px' }}>CONTACT</h4>
          <ul>
            <li>
              <Link to="/fallback" style={{ color: 'white' }}>
                General Switchboard: buro@4darchitects.nl
              </Link>
            </li>
            <li>
              <Link to="/fallback" style={{ color: 'white' }}>
                FAQ
              </Link>
            </li>
          </ul>
        </div>
        <div>
          <h4 style={{ marginBottom: '10px' }}>DIRECTLY TO</h4>
          <ul>
            <li>
              <a href="https://www.gtb-lab.com" style={{ color: 'white' }}>
                News
              </a>
            </li>
            <li>
              <Link to="/fallback" style={{ color: 'white' }}>
                Calendar
              </Link>
            </li>
          </ul>
        </div>
        <div>
          <h4 style={{ marginBottom: '10px' }}>
            DO YOU WANT THE LATEST <br /> INNOVATIONS IN YOUR INBOX?
          </h4>
          <ul>
            <li>
              <Link to="/fallback" style={{ color: 'white' }}>
                Sign up for our newsletter today
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div className="footer-banner">
          <img
            src={logo}
            style={{ height: '50px', position: 'absolute', left: '80px' }}
            alt="4dLogo"
          ></img>
          <img
            src={EULogo}
            style={{ height: '80px', position: 'absolute', right: '80px' }}
            alt="EULogo"
          ></img>
          <div className="footer-banner-whiteLine"></div>
        </div>
      </div>
    </div>
  )
}

export default Footer
