import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, Link, useParams, useHistory } from 'react-router-dom'
import { ProjectProps } from '../../components/Project'
import { fetchElements } from '../../redux/actions/projects'
import { useAppSelector } from '../../redux/hooks'
import {
  Tab,
  Icon,
  Table,
  Button,
  Image,
  Container,
  Input,
  Message,
  Segment,
  Header,
  Divider,
  Modal as ModalUI,
  Pagination,
} from 'semantic-ui-react'
import UploadButton from '../../components/UploadButton'
import Modal from '../../components/Modal'
import { getSingleProject } from '../../redux/actions/projects'
import { uploadPDF } from '../../redux/actions/projects'
import defaultBackgroundImage from '../../images/defaultBackgroundImage.png'
import { Element, ElementParameter, ProjectElement } from '../../types/elements'
import { syncProjectToDDC } from '../../services/ddc.api'

const ProjectScreen = () => {
  const history = useHistory()
  /* Helper Function for Images */
  const images = useAppSelector(
    state => state.ProjectsReducer.selectedProject.images
  )

  const [
    isProjectElementParametersShown,
    setIsProjectElementParametersShown,
  ] = useState(false)

  const [element, setElement] = useState<Element>()

  const mappedImages = () => {
    if (images.length === 0) {
      if (role === 'ProjectOwner') {
        return (
          <Segment textAlign="center" style={{ border: 'none' }}>
            <Message>
              <Message.Content>
                <Message.Header>
                  <Header style={{ backgroundColor: 'white' }}>
                    No photos shared yet!
                  </Header>
                </Message.Header>
                <p>Please upload photos to view them</p>
              </Message.Content>
            </Message>
          </Segment>
        )
      }
      return (
        <Segment textAlign="center" style={{ border: 'none' }}>
          <Message>
            <Message.Content>
              <Message.Header>
                <Header style={{ backgroundColor: 'white' }}>
                  No photos shared yet!
                </Header>
              </Message.Header>
              <p>Please upload photos to view them</p>
            </Message.Content>
          </Message>
          <UploadButton route={`/project/edit/${id}`} />
        </Segment>
      )
    }
    const mappedImages = images.map((image: string) => {
      const imageName = image.split('/')[2]
      return (
        <div
          key={image}
          style={{ display: 'inline', height: '400px !important' }}
        >
          <Image
            src={`${process.env.REACT_APP_API_URL}/images/${image}`}
            // crossOrigin="cross-origin"
            className="projectImage"
            onClick={() => {
              imageInfo(imageName, image)
              setShowImage(true)
            }}
          />
        </div>
      )
    })
    return mappedImages
  }
  /* Helper Function for PDF Files */
  const files = useAppSelector(
    state => state.ProjectsReducer.selectedProject?.files
  )

  const pdfFiles = files.filter((file: string) => {
    return file.indexOf('.pdf') !== -1
  })

  const excelFiles = files.filter((file: string) => {
    return file.indexOf('.csv') !== -1
  })

  const mappedPDFFiles = () => {
    if (pdfFiles.length === 0) {
      if (role === 'ProjectOwner') {
        return (
          <Segment textAlign="center" style={{ border: 'none' }}>
            <Message>
              <Message.Content>
                <Message.Header>
                  <Header style={{ backgroundColor: 'white' }}>
                    No files shared yet!
                  </Header>
                </Message.Header>
                <p>Please upload files to view them</p>
              </Message.Content>
            </Message>
          </Segment>
        )
      }
      return (
        <Segment textAlign="center" style={{ border: 'none' }}>
          <Message>
            <Message.Content>
              <Message.Header>
                <Header style={{ backgroundColor: 'white' }}>
                  No files shared yet!
                </Header>
              </Message.Header>
              <p>Please upload files to view them</p>
            </Message.Content>
          </Message>
          <Input
            id="uploadPDF"
            name="upload"
            type="file"
            accept="application/pdf"
            onChange={(event: any) => {
              dispatch(
                uploadPDF(
                  {
                    id: selectedProject._id,
                    files: event.currentTarget?.files,
                  },
                  history
                )
              )
            }}
            multiple
            style={{ border: '0', display: 'none' }}
          ></Input>
          <br />
          <Button
            className="uploadButton"
            size="big"
            style={{
              backgroundColor: '#ffc000',
              color: 'white',
              marginTop: '15px',
            }}
          >
            <label htmlFor="uploadPDF">
              <Button.Content>Upload</Button.Content>
            </label>
          </Button>
        </Segment>
      )
    }
    const mappedPDFFiles = pdfFiles.map((file: string) => {
      let name = file.split('/')[2]
      if (role === 'ProjectOwner') {
        return (
          <div key={file}>
            <div className="element__link">
              <div>
                <Icon name="file pdf" size="huge" />
              </div>
              {name}
            </div>
            <div className="uploadButton--container"></div>
          </div>
        )
      }
      return (
        <div key={file}>
          <div className="element__link">
            <Icon name="file pdf" size="huge" />
            {name}
            <div>
              <br />
              <Button.Group>
                <Button color="green">
                  <a
                    href={`${process.env.REACT_APP_API_URL}/download/?id=${id}&name=${name}`}
                    // href={`http://localhost:8080/download/?id=62063caa021d866c02f5dfd4&name=OrhanSecicWalterCodeCV%20-%20Copy.pdf`}
                    target="_blank"
                    download={`${name}`}
                    style={{ color: 'white' }}
                    rel="noreferrer"
                  >
                    Download
                  </a>
                </Button>
                <Button.Or />
                <Button
                  color="red"
                  onClick={() => {
                    pdfInfo(name, file)
                    setShowPDF(true)
                  }}
                >
                  Delete
                </Button>
              </Button.Group>
            </div>
          </div>
          <Divider />
        </div>
      )
    })

    if (role === 'ProjectOwner') {
      return <Container textAlign="center">{mappedPDFFiles}</Container>
    }
    return (
      <Container textAlign="center">
        {mappedPDFFiles}
        <Input
          id="uploadPDF"
          name="upload"
          type="file"
          accept="application/pdf"
          onChange={(event: any) => {
            dispatch(
              uploadPDF(
                {
                  id: selectedProject._id,
                  files: event.currentTarget?.files,
                },
                history
              )
            )
          }}
          multiple
          style={{ border: '0', display: 'none' }}
        ></Input>
        <Button
          className="uploadButton"
          size="big"
          style={{
            backgroundColor: '#ffc000',
            color: 'white',
            marginTop: '15px',
          }}
        >
          <label htmlFor="uploadPDF">
            <Button.Content>Upload</Button.Content>
          </label>
        </Button>
      </Container>
    )
  }

  const mappedExcelFiles = () => {
    if (excelFiles.length === 0) {
      if (role === 'ProjectOwner') {
        return (
          <Segment textAlign="center" style={{ border: 'none' }}>
            <Message>
              <Message.Content>
                <Message.Header>
                  <Header style={{ backgroundColor: 'white' }}>
                    No files shared yet!
                  </Header>
                </Message.Header>
                <p>Please upload files to view them</p>
              </Message.Content>
            </Message>
          </Segment>
        )
      }
      return (
        <Segment textAlign="center" style={{ border: 'none' }}>
          <Message>
            <Message.Content>
              <Message.Header>
                <Header style={{ backgroundColor: 'white' }}>
                  No files shared yet!
                </Header>
              </Message.Header>
              <p>Please upload files to view them</p>
            </Message.Content>
          </Message>
          <Input
            id="uploadExcel"
            name="upload"
            type="file"
            accept="application/csv"
            onChange={(event: any) => {
              dispatch(
                uploadPDF(
                  {
                    id: selectedProject._id,
                    files: event.currentTarget?.files,
                  },
                  history
                )
              )
            }}
            multiple
            style={{ border: '0', display: 'none' }}
          ></Input>
          <br />
          <Button
            className="uploadButton"
            size="big"
            style={{
              backgroundColor: '#ffc000',
              color: 'white',
              marginTop: '15px',
            }}
          >
            <label htmlFor="uploadExcel">
              <Button.Content>Upload</Button.Content>
            </label>
          </Button>
        </Segment>
      )
    }
    const mappedExcelFiles = excelFiles.map((file: string) => {
      let name = file.split('/')[2]
      if (role === 'ProjectOwner') {
        return (
          <div key={file}>
            <div className="element__link">
              <div>
                <Icon name="file excel" size="huge" />
              </div>
              {name}
            </div>
            <div className="uploadButton--container"></div>
          </div>
        )
      }
      return (
        <div key={file}>
          <div className="element__link">
            <Icon name="file excel" size="huge" />
            {name}
            <div>
              <br />
              <Button.Group>
                <Button color="green">
                  <a
                    href={`${process.env.REACT_APP_API_URL}/download/?id=${id}&name=${name}`}
                    target="_blank"
                    download={`${name}`}
                    style={{ color: 'white' }}
                    rel="noreferrer"
                  >
                    Download
                  </a>
                </Button>
                <Button.Or />
                <Button
                  color="red"
                  onClick={() => {
                    pdfInfo(name, file)
                    setShowPDF(true)
                  }}
                >
                  Delete
                </Button>
              </Button.Group>
            </div>
          </div>
          <Divider />
        </div>
      )
    })

    if (role === 'ProjectOwner') {
      return <Container textAlign="center">{mappedExcelFiles}</Container>
    }
    return (
      <Container textAlign="center">
        {mappedExcelFiles}
        <Input
          id="uploadExcel"
          name="upload"
          type="file"
          accept="application/csv"
          onChange={(event: any) => {
            dispatch(
              uploadPDF(
                {
                  id: selectedProject._id,
                  files: event.currentTarget?.files,
                },
                history
              )
            )
          }}
          multiple
          style={{ border: '0', display: 'none' }}
        ></Input>
        <Button
          className="uploadButton"
          size="big"
          style={{
            backgroundColor: '#ffc000',
            color: 'white',
            marginTop: '15px',
          }}
        >
          <label htmlFor="uploadExcel">
            <Button.Content>Upload</Button.Content>
          </label>
        </Button>
      </Container>
    )
  }

  const panes = [
    {
      menuItem: 'Gallery',
      render: () => (
        <Tab.Pane>
          <Container textAlign="center">
            <Image.Group size="medium">{mappedImages()}</Image.Group>
          </Container>
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Elements',
      render: () => (
        <Tab.Pane>
          <Elements
            setIsProjectElementParametersShown={
              setIsProjectElementParametersShown
            }
            setElement={setElement}
            setActivePage={setActivePage}
          />
          {element && (
            <ModalUI
              closeIcon
              size={'large'}
              open={isProjectElementParametersShown}
              onClose={closeModal}
            >
              <ModalUI.Header>Parameters</ModalUI.Header>
              <ModalUI.Content scrolling>
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Name</Table.HeaderCell>
                      <Table.HeaderCell>Value</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {element.Parameters.map(
                      (p: ElementParameter) =>
                        p.Name !== 'Element Connections' &&
                        p.Name !== 'Rp Indicators' && (
                          <Table.Row key={p._id}>
                            <Table.Cell>{p.Name}</Table.Cell>
                            <Table.Cell>
                              {p.Value !== null
                                ? p.Value.toString()
                                : 'no data'}
                            </Table.Cell>
                          </Table.Row>
                        )
                    )}
                  </Table.Body>
                </Table>
              </ModalUI.Content>
            </ModalUI>
          )}
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Excel.csv',
      render: () => <Tab.Pane>{mappedExcelFiles()}</Tab.Pane>,
    },
    {
      menuItem: 'Pdf',
      render: () => <Tab.Pane>{mappedPDFFiles()}</Tab.Pane>,
    },
  ]

  const Model = [
    {
      menuItem: 'Description',
      render: () => (
        <Tab.Pane>
          {selectedProject.descriptionImage ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <div style={{ margin: '0 20px', width: '50%' }}>
                <Image
                  // crossOrigin="cross-origin"
                  src={`${process.env.REACT_APP_API_URL}/images/${selectedProject.descriptionImage}`}
                />
              </div>
              <div
                style={{ margin: '0 20px', width: '50%', textAlign: 'justify' }}
              >
                <p className="project__description">
                  {selectedProject.description}
                </p>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <div style={{ margin: '0 20px', width: '50%' }}>
                <Image
                  // crossOrigin="cross-origin"
                  src={defaultBackgroundImage}
                />
              </div>
              <div
                style={{ margin: '0 20px', width: '50%', textAlign: 'justify' }}
              >
                <p className="project__description">
                  {selectedProject.description}
                </p>
              </div>
            </div>
          )}
        </Tab.Pane>
      ),
    },
    {
      menuItem: '3d View',
      render: () => {
        if (
          selectedProject?.url_3d.includes('https://myhub.autodesk360.com') ||
          selectedProject?.url_3d.includes('https://a360.co/')
        ) {
          return (
            <Tab.Pane>
              <iframe
                title="embed-model"
                //"https://myhub.autodesk360.com/ue2d9f098/g/shares/SH9285eQTcf875d3c539f556c24544c179c8"
                src={selectedProject?.url_3d}
                width="100%"
                height="800px"
                allowFullScreen={true}
                frameBorder="0"
              ></iframe>{' '}
            </Tab.Pane>
          )
        } else {
          return (
            <Tab.Pane>
              <Segment textAlign="center" style={{ border: 'none' }}>
                <Message>
                  <Message.Content>
                    <Message.Header>
                      <Header style={{ backgroundColor: 'white' }}>
                        Shared 3D Link was invalid!
                      </Header>
                    </Message.Header>
                    <p>Please share a "myhub.autodesk360" or "a360.co" Link</p>
                  </Message.Content>
                </Message>
              </Segment>
            </Tab.Pane>
          )
        }
      },
    },
  ]

  /* Image Info helper function */
  const [imageName, setImageName] = useState('')
  const [fullImageName, setFullImageName] = useState('')

  const imageInfo = (name: string, image: string) => {
    setImageName(name)
    setFullImageName(image)
  }

  /* Image Info helper function */
  const [pdfName, setpdfName] = useState('')
  const [fullPDFName, setFullPDFName] = useState('')

  const pdfInfo = (name: string, pdf: string) => {
    setpdfName(name)
    setFullPDFName(pdf)
  }

  const [show, setShow] = useState(false)
  const [showImage, setShowImage] = useState(false)
  const [showPDF, setShowPDF] = useState(false)

  const project = useLocation<ProjectProps>().state
  const dispatch = useDispatch()
  const { id } = useParams<{ id: string }>()
  const selectedProject = useAppSelector(
    state => state.ProjectsReducer.selectedProject
  )
  const role = useAppSelector(state => state.AuthReducer.user?.role)

  const [activePage, setActivePage] = useState(1)

  useEffect(() => {
    if (selectedProject.elementsId) {
      dispatch(fetchElements(selectedProject.elementsId, activePage, 10))
    }
  }, [selectedProject.elementsId, dispatch])

  useEffect(() => {
    dispatch(getSingleProject(id))
  }, [])

  useEffect(() => {
    dispatch(fetchElements(selectedProject.elementsId, activePage, 10))
  }, [activePage])

  // const [element, setElement] = useState<ProjectElement>()

  const closeModal = () => {
    setShow(false)
    setShowImage(false)
    setShowPDF(false)
    setIsProjectElementParametersShown(false)
  }
  if (role === 'ProjectOwner') {
    return (
      <div className="project__main">
        <div style={{ textAlign: 'center' }}>
          <h2 className="project__header">{project.projectName}</h2>
          <Tab
            menu={{
              secondary: true,
              pointing: true,
              style: {
                display: 'flex',
                justifyContent: 'center',
                marginTop: '10px',
              },
            }}
            panes={Model}
          />
        </div>
        <div className="project__tableDiv">
          <Table className="project__table">
            <Table.Header>
              <Table.Row textAlign="center">
                <Table.HeaderCell>Location</Table.HeaderCell>
                <Table.HeaderCell>Owner</Table.HeaderCell>
                <Table.HeaderCell>Date of creation</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row textAlign="center">
                <Table.Cell>{project.location}</Table.Cell>
                <Table.Cell>
                  {selectedProject.owner
                    ? `${selectedProject.owner.profile.firstName} ${selectedProject.owner.profile.lastName}`
                    : ''}
                </Table.Cell>
                <Table.Cell>
                  {project.date && new Date(project.date).toLocaleDateString()}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
        <Tab
          menu={{
            secondary: true,
            pointing: true,
            style: {
              display: 'flex',
              justifyContent: 'center',
              marginTop: '10px',
            },
          }}
          panes={panes}
        />
      </div>
    )
  }
  return (
    <div className="project__main">
      <Modal
        content={project.projectName}
        screen="project"
        elementID={id}
        isOpen={show}
        closeModal={closeModal}
      />
      <Modal
        content={imageName}
        screen="singleProjectGallery"
        elementID={id}
        isOpen={showImage}
        closeModal={closeModal}
        fileName={fullImageName}
      />
      <Modal
        content={pdfName}
        screen="singleProjectPDF"
        elementID={id}
        isOpen={showPDF}
        closeModal={closeModal}
        fileName={fullPDFName}
      />
      <div className="project__actionButtons">
        <Button
          className="actionButtons"
          color="green"
          onClick={() => syncProjectToDDC(project)}
        >
          Sync to DDC
        </Button>
        <Button
          className="actionButtons"
          negative
          onClick={() => setShow(true)}
        >
          Delete
        </Button>
        <Link to={`/project/edit/${id}`}>
          <Button className="actionButtons" color="yellow">
            Edit
          </Button>
        </Link>
      </div>
      <div style={{ textAlign: 'center' }}>
        <h2 className="project__header">{project.projectName}</h2>
        <Tab
          menu={{
            secondary: true,
            pointing: true,
            style: {
              display: 'flex',
              justifyContent: 'center',
              marginTop: '10px',
            },
          }}
          panes={Model}
        />
      </div>
      <div className="project__tableDiv">
        <Table className="project__table">
          <Table.Header>
            <Table.Row textAlign="center">
              <Table.HeaderCell>Location</Table.HeaderCell>
              <Table.HeaderCell>Owner</Table.HeaderCell>
              <Table.HeaderCell>Date of creation</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row textAlign="center">
              <Table.Cell>{project.location}</Table.Cell>
              <Table.Cell>
                {selectedProject.owner
                  ? `${selectedProject.owner.profile.firstName} ${selectedProject.owner.profile.lastName}`
                  : ''}
              </Table.Cell>
              <Table.Cell>
                {project.date && new Date(project.date).toLocaleDateString()}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
      <Tab
        menu={{
          secondary: true,
          pointing: true,
          style: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: '10px',
          },
        }}
        panes={panes}
      />
    </div>
  )
}

const Elements = ({
  setIsProjectElementParametersShown,
  setElement,
  setActivePage,
}: {
  setIsProjectElementParametersShown: (
    isProjectElementParametersShown: boolean
  ) => void
  setElement: (element: Element) => void
  setActivePage: (activePage: number) => void
}): JSX.Element => {
  const showParametersModal = (element: Element) => {
    setElement(element)
    setIsProjectElementParametersShown(true)
  }

  const { elements, total } = useAppSelector(
    state => state.ProjectsReducer?.elements
  )

  if (!elements) {
    return (
      <Segment textAlign="center" style={{ border: 'none' }}>
        <Message>
          <Message.Content>
            <Message.Header>
              <Header style={{ backgroundColor: 'white' }}>
                No elements shared yet!
              </Header>
            </Message.Header>
            <p>No elements associated with this project</p>
          </Message.Content>
        </Message>
      </Segment>
    )
  } else if (elements.length === 0) {
    return (
      <Segment textAlign="center" style={{ border: 'none' }}>
        <Message>
          <Message.Content>
            <Message.Header>
              <Header style={{ backgroundColor: 'white' }}>
                No elements shared yet!
              </Header>
            </Message.Header>
            <p>No elements associated with this project</p>
          </Message.Content>
        </Message>
      </Segment>
    )
  } else {
    return (
      <Segment>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Function</Table.HeaderCell>
              <Table.HeaderCell>Level</Table.HeaderCell>
              <Table.HeaderCell>Category</Table.HeaderCell>
              <Table.HeaderCell>Parameters</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {elements.map((element: ProjectElement) => (
              <Table.Row key={element.elements.ElementId}>
                <Table.Cell>{element.elements.Function}</Table.Cell>
                <Table.Cell>{element.elements.Level}</Table.Cell>
                <Table.Cell>{element.elements.Category}</Table.Cell>
                <Table.Cell style={{ textAlign: 'center' }}>
                  <Button
                    color="blue"
                    onClick={() => showParametersModal(element.elements)}
                  >
                    {element.elements.Parameters.length} parameters
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <div>
          <Pagination
            defaultActivePage={1}
            totalPages={Math.ceil(total / 10)}
            onPageChange={(event, { activePage }) =>
              setActivePage(activePage as number)
            }
          />
        </div>
      </Segment>
    )
  }
}

export default ProjectScreen
