import React from 'react'
import { useDispatch } from 'react-redux'
import { removeAlert } from '../redux/actions/alerts'

type SnackbarProps = {
  content: string
  id: string
}

const Snackbar = ({ content, id }: SnackbarProps): JSX.Element => {
  const dispatch = useDispatch()

  return (
    <div className="snackbar snackbar__position">
      <span className="snackbar__content">{content}</span>
      <span
        className="snackbar--close"
        onClick={() => {
          dispatch(removeAlert(id))
        }}
      >
        Close
      </span>
    </div>
  )
}

export default Snackbar
