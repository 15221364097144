import api from '../../services/api'
import { AnyAction, Dispatch } from 'redux'
import { RootState } from '../store'
import { ThunkAction } from 'redux-thunk'
import { History } from 'history'
import { createAlert } from './alerts'

export const getAllUsers = () => async (
  dispatch: Dispatch<Action<string, string | allUsers>>
) => {
  try {
    const data = await api.getAllUsers()
    dispatch({
      type: 'GET_USERS_SUCCESS',
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const getAllProjectOwners = () => async (
  dispatch: Dispatch<Action<string, string | allProjectOwners>>
) => {
  try {
    const data = await api.getAllProjectOwners()
    dispatch({
      type: 'GET_PROJECTOWNERS_SUCCESS',
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const deleteUser = (
  id: string,
  pageUserID: string | undefined,
  history: History
): ThunkAction<void, RootState, unknown, AnyAction> => async dispatch => {
  try {
    await api.deleteUser(id)
    dispatch(createAlert('User Deleted Successfully'))
    history.push('/')
    history.push(`/user/superadmin/${pageUserID}`)
  } catch (err) {
    console.error(err)
  }
}
