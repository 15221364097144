import React from 'react'
import { Header, Icon } from 'semantic-ui-react'

import gtbLab from '../images/gtbLab.png'
const SocialLinks = () => {
  return (
    <div className="socialLinks">
      <Header
        as="h2"
        style={{
          backgroundColor: 'transparent',
          fontWeight: 'bold',
          marginBottom: '40px',
          padding: '10px',
        }}
      >
        FOLLOW US ON SOCIAL MEDIA
        <Header.Subheader>
          Stay up to date with our latest news, activities and vacancies
        </Header.Subheader>
      </Header>
      <div className="socialLinks__apps">
        <a href="https://www.linkedin.com/" target="_blank" rel="noreferrer">
          <Icon name="linkedin" color="blue" size="big" />
          <br />
          <span className="socialLinks__link">LINKEDIN</span>
        </a>
        <a href="https://www.facebook.com/" target="_blank" rel="noreferrer">
          <Icon name="facebook" color="blue" size="big" />
          <br />
          <span className="socialLinks__link">FACEBOOK</span>
        </a>
        <a href="https://www.twitter.com/" target="_blank" rel="noreferrer">
          <Icon name="twitter" color="blue" size="big" />
          <br />
          <span className="socialLinks__link">4D RESEARCH</span>
        </a>
        <a href="https://www.youtube.com/" target="_blank" rel="noreferrer">
          <Icon name="youtube" color="red" size="big" />
          <br />
          <span className="socialLinks__link">YOUTUBE</span>
        </a>
      </div>
      <a
        href="https://www.greentransformablebuilding.com/"
        target="_blank"
        rel="noreferrer"
      >
        <img className="socialLinks__logo" src={gtbLab} alt="GTBLab Logo" />
      </a>
    </div>
  )
}

export default SocialLinks
