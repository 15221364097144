import { History } from 'history'
import { AnyAction, Dispatch } from 'redux'
import { ThunkAction } from 'redux-thunk'
import api from '../../services/api'
import { RootState } from '../store'
import {
  FETCH_USER_FAILURE,
  FETCH_USER_SUCCESS,
  LOGIN_FAILED,
  LOGOUT,
  SIGNUP_SUCCESS,
  SIGNUP_FAILED,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,
} from '../types'
import { createAlert } from './alerts'

export const login = (
  formData: LoginFormData,
  history: History
): ThunkAction<void, RootState, unknown, AnyAction> => async dispatch => {
  try {
    const data = await api.login(formData)
    dispatch(createAlert(data.message))
    dispatch({
      type: FETCH_USER_SUCCESS,
      payload: data.user,
    })
    history.push('/')
  } catch (err) {
    dispatch({
      type: LOGIN_FAILED,
    })
    dispatch(createAlert('Wrong Email or Password'))
  }
}

export const logout = (): ThunkAction<
  void,
  RootState,
  unknown,
  AnyAction
> => async dispatch => {
  try {
    await api.logout()
    dispatch({
      type: LOGOUT,
    })
    dispatch(createAlert('Logout Successful'))
  } catch (err) {
    console.error(err)
    dispatch(createAlert('Logout Unsuccessful'))
  }
}

export const getSystemUser = () => async (
  dispatch: Dispatch<Action<string, IUser | string>>
) => {
  try {
    const user = await api.getSystemUser()
    dispatch({
      type: FETCH_USER_SUCCESS,
      payload: user,
    })
  } catch (err) {
    console.error(err)
    dispatch({
      type: FETCH_USER_FAILURE,
      // payload: err.message,
    })
  }
}

export const createUser = (
  formData: CreateUserFormData,
  history: History
): ThunkAction<void, RootState, unknown, AnyAction> => async dispatch => {
  try {
    await api.createUser(formData)
    dispatch({
      type: SIGNUP_SUCCESS,
      payload: 'User Has Been Created!',
    })

    dispatch(createAlert('User Has Been Created'))

    history.push('/')
  } catch (err) {
    dispatch({
      type: SIGNUP_FAILED,
    })
    dispatch(createAlert('Email Address Already in Use'))
  }
}

export const editUser = (
  formData: EditUserFormData,
  history: History
): ThunkAction<void, RootState, unknown, AnyAction> => async dispatch => {
  try {
    const data = await api.editUser(formData)
    dispatch({
      type: EDIT_USER_SUCCESS,
      payload: data.message,
    })
    const res = await api.editUserInformation(formData)
    console.log(res.message)
    dispatch(createAlert('User Edited Successfully'))
    history.push('/')
  } catch (err) {
    dispatch({
      type: EDIT_USER_FAILURE,
    })
    dispatch(createAlert('Credentials Already in Use'))
  }
}
