import React from 'react'
import { Link } from 'react-router-dom'

type propsValues = {
  content: string
  route: string
}

const AddButton = ({ content, route }: propsValues) => {
  return (
    <div className="container">
      <Link
        to={route}
        className="big ui vertical animated button"
        id="addButton"
        style={{ backgroundColor: '#ffc000', color: 'white' }}
      >
        <div className="visible content">{content}</div>
        <div className="hidden content">
          <i className="plus icon" />
        </div>
      </Link>
    </div>
  )
}

export default AddButton
