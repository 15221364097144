/* REDUCERS */
import { combineReducers } from 'redux'
import AuthReducer from './auth'
import ProjectsReducer from './projects'
import { MaterialsReducer } from './materials'
import AlertsReducer from './alerts'
import { UsersReducer } from './users'

export default combineReducers({
  ProjectsReducer,
  AuthReducer,
  MaterialsReducer,
  AlertsReducer,
  UsersReducer,
})
