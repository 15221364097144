import { useEffect } from 'react'
import { FormikTouched, useFormik } from 'formik'
import * as yup from 'yup'
import {
  Container,
  Segment,
  Header,
  Button,
  Checkbox,
  Loader,
} from 'semantic-ui-react'
import TextInput from '../../components/TextInput'
import { useDispatch } from 'react-redux'
import { editMaterial, getSingleMaterial } from '../../redux/actions/materials'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useAppSelector } from '../../redux/hooks'

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  co2: yup.number().required('co2 number is required'),
  density: yup
    .number()
    .positive('It must be a positive number')
    .required('Density number is required'),
  isHazardous: yup.boolean().required('This field is required'),
  hasHazardousCoating: yup.boolean().required('This field is required'),
  technicalLifecycle: yup
    .number()
    .positive('It must be a positive number')
    .required('Technical Lifecycle number is required'),
})
const EditMaterialScreen = ({ location }: any) => {
  const selectedMaterial = useAppSelector(
    state => state.MaterialsReducer.singleMaterial
  )
  const dispatch = useDispatch()
  const params = useParams<{ id: string }>()
  const history = useHistory()

  useEffect(() => {
    const informationSetter = async () => {
      await dispatch(getSingleMaterial(params.id))
    }

    informationSetter()
  }, [dispatch])

  const {
    handleSubmit,
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
  } = useFormik({
    initialValues: {
      _id: params.id,
      name: `${location.state?.materialName}`,
      co2: location.state?.materialCO2,
      density: location.state?.materialDensity,
      isHazardous: location.state?.materialHazardous,
      hasHazardousCoating: location.state?.materialHazardousCoating,
      technicalLifecycle: location.state?.materialTechnicalLifecycle,
    },
    validationSchema,
    onSubmit: () => {
      dispatch(editMaterial(values, history))
    },
  })
  if (params.id) {
    return (
      <div>
        <Container className="login__container">
          <div className="backButtonContainer"></div>
          <Segment raised color="teal">
            <Header className="signup__title" as="h3">
              Edit Material
            </Header>
            <form onSubmit={handleSubmit} className="ui fluid form login__form">
              <TextInput
                name="name"
                id="name"
                label="Enter Name"
                placeholder={`${selectedMaterial.name}`}
                icon="circle icon"
                type="text"
                value={values.name}
                touched={touched.name}
                error={errors.name}
                handleBlur={handleBlur}
                handleChange={handleChange}
              />
              <TextInput
                name="co2"
                id="co2"
                label="Enter co2"
                placeholder={`${selectedMaterial.co2}`}
                icon="exclamation triangle icon"
                type="number"
                value={values.co2}
                touched={touched.co2 as any}
                error={errors.co2 as any}
                handleBlur={handleBlur}
                handleChange={handleChange}
              />
              <TextInput
                name="density"
                id="density"
                label="Enter Density"
                placeholder={`${selectedMaterial.density}`}
                icon="circle icon"
                type="number"
                value={values.density}
                touched={touched.density as any}
                error={errors.density as any}
                handleBlur={handleBlur}
                handleChange={handleChange}
              />
              <div id="signup__field" className="field checkboxContainer">
                <div style={{ textAlign: 'center' }}>
                  <label className="ui__label">Is it Hazardous?</label>
                  <br />
                  <Checkbox
                    className="checkbox"
                    name="isHazardous"
                    id="isHazardous"
                    checked={values.isHazardous}
                    label="Yes"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {errors?.isHazardous && touched.isHazardous ? (
                    <div id="errorMessage">{errors.isHazardous}</div>
                  ) : null}
                </div>
                <div style={{ textAlign: 'center' }}>
                  <label className="ui__label">
                    Does it have Hazardous Coating?
                  </label>
                  <br />
                  <Checkbox
                    className="checkbox"
                    name="hasHazardousCoating"
                    id="hasHazardousCoating"
                    checked={values.hasHazardousCoating}
                    label="Yes"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {errors?.hasHazardousCoating &&
                  touched.hasHazardousCoating ? (
                    <div id="errorMessage">{errors.hasHazardousCoating}</div>
                  ) : null}
                </div>
              </div>
              <TextInput
                name="technicalLifecycle"
                id="technicalLifecycle"
                label="Enter Technical Lifecycle"
                placeholder={`${selectedMaterial.technicalLifecycle}`}
                icon="circle icon"
                type="number"
                value={values.technicalLifecycle}
                touched={touched.technicalLifecycle as any}
                error={errors.technicalLifecycle as any}
                handleBlur={handleBlur}
                handleChange={handleChange}
              />
              <Button
                className="btn__login"
                fluid={true}
                style={{ backgroundColor: '#ffc000', color: 'white' }}
                type="submit"
              >
                EDIT MATERIAL
              </Button>
            </form>
          </Segment>
        </Container>
      </div>
    )
  } else {
    return (
      <Container>
        <Segment placeholder>
          <Loader active>Loading Values</Loader>
        </Segment>
      </Container>
    )
  }
}

export default EditMaterialScreen
