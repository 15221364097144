import { ProjectElement } from '../../types/elements'
import {
  FETCH_ELEMENTS_FAILURE,
  FETCH_ELEMENTS_SUCCESS,
  FETCH_PROJECTS_FAILURE,
  FETCH_PROJECTS_SUCCESS,
  SINGLE_PROJECT_SUCCESS,
} from '../types'

export type ProjectsState = {
  projects: IProject[]
  elements: ElementsPayload
  selectedProject: IProject | any
  message: string
}

export type ElementsPayload = {
  elements: ProjectElement[]
  total: number
}

const initialState: ProjectsState = {
  projects: [],
  elements: {
    elements: [],
    total: 0,
  },
  selectedProject: {
    _id: '',
    name: '',
    description: '',
    location: '',
    owner: '',
    elementsId: '',
    createdBy: '',
    createdAt: '',
    updatedAt: '',
    images: [],
    files: [],
    elements: [],
  },
  message: '',
}

export default function ProjectsReducer(
  state = initialState,
  action: Action<string, IProject[] | ElementsPayload | IProject>
): ProjectsState {
  const { type, payload } = action

  switch (type) {
    case FETCH_PROJECTS_SUCCESS:
      return { ...state, projects: payload as IProject[] }
    case FETCH_PROJECTS_FAILURE:
    case FETCH_ELEMENTS_FAILURE:
      return { ...state }
    case FETCH_ELEMENTS_SUCCESS:
      return { ...state, elements: payload as ElementsPayload }
    case SINGLE_PROJECT_SUCCESS:
      return {
        ...state,
        selectedProject: payload as IProject,
      }
    case 'DELETE_PROJECT_SUCCESS':
      return state
    default:
      return state
  }
}
