import axios, { AxiosInstance, AxiosResponse } from 'axios'

const axios_instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:8080/',
  withCredentials: true,
})

class Api {
  api: AxiosInstance

  constructor() {
    this.api = axios_instance
  }

  login = async (formData: LoginFormData) => {
    try {
      const res: AxiosResponse<IUserResponse> = await this.api.post(
        '/auth/login',
        { email: formData.email, password: formData.password }
      )
      return res.data
    } catch (err) {
      console.error(err)
      throw err
    }
  }

  logout = async () => {
    try {
      await this.api.post('/auth/logout', {})
    } catch (err) {
      console.error(err)
    }
  }

  createUser = async (formData: CreateUserFormData) => {
    try {
      const res: AxiosResponse<IUserResponse> = await this.api.post('/users', {
        email: formData.email,
        password: formData.password,
        role: formData.role,
        profile: formData.profile,
      })
      return res.data
    } catch (err) {
      console.error(err)
      throw err
    }
  }

  editUser = async (formData: EditUserFormData) => {
    try {
      const body = {
        _id: formData._id,
        email: formData.email,
        password: formData.password,
      }

      const res: AxiosResponse<IUserResponse> = await this.api.put(
        `/users`,
        body
      )
      return res.data
    } catch (err) {
      console.error(err)
      throw err
    }
  }

  editUserInformation = async (formData: EditUserFormData) => {
    try {
      const body = {
        _id: formData._id,
        firstName: formData.firstName,
        lastName: formData.lastName,
        address: formData.address,
      }

      const res: AxiosResponse<IUserResponse> = await this.api.put(
        `/users/profile`,
        body
      )
      return res.data
    } catch (err) {
      console.error(err)
      throw err
    }
  }

  addProject = async (formData: AddProjectFormData) => {
    try {
      const requestData = new FormData()
      requestData.append('name', formData.name)
      requestData.append('description', formData.description)
      requestData.append('location', formData.location)
      requestData.append('owner', formData.ownerID)
      requestData.append('url_3d', formData.link)

      const ImageDescription = Array.from(formData.imageDes)
      ImageDescription.map((imageDes: any) =>
        requestData.append('file', imageDes)
      )

      const res: AxiosResponse<IProjectResponse> = await this.api.post(
        '/projects',
        requestData,
        {
          withCredentials: true,
        }
      )
      return res
    } catch (error) {
      throw error
    }
  }

  deleteProject = async (id: string) => {
    try {
      const res: AxiosResponse<IUserResponse> = await this.api.delete(
        `/projects/${id}`
      )
      return res
    } catch (error) {
      console.error(error)
    }
  }

  editProject = async (formData: EditProjectFormData) => {
    try {
      const requestData = new FormData()
      requestData.append('name', formData.name)
      requestData.append('description', formData.description)
      requestData.append('location', formData.location)
      requestData.append('owner', formData.ownerID)
      requestData.append('_id', formData._id)
      requestData.append('url_3d', formData.link)
      const ArrayFileList = Array.from(formData.imageDES)
      ArrayFileList.map((file: any) => {
        requestData.append('file', file)
        return file
      })
      await this.api.put(`/projects`, requestData, {
        withCredentials: true,
      })
    } catch (error) {
      console.error(error)
    }
  }

  addMaterial = async (formData: AddMaterialFormData) => {
    try {
      const res = await this.api.post('/materials', {
        name: formData.name,
        co2: formData.co2,
        density: formData.density,
        isHazardous: formData.isHazardous,
        hasHazardousCoating: formData.hasHazardousCoating,
        technicalLifecycle: formData.technicalLifecycle,
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  deleteMaterial = async (id: string) => {
    try {
      await this.api.delete(`/materials/${id}`)
    } catch (error) {
      console.error(error)
    }
  }

  editMaterial = async (formData: EditMaterialFormData) => {
    try {
      const response = await this.api.put(`/materials`, {
        _id: formData._id,
        name: formData.name,
        co2: formData.co2,
        density: formData.density,
        isHazardous: formData.isHazardous,
        hasHazardousCoating: formData.hasHazardousCoating,
        technicalLifecycle: formData.technicalLifecycle,
      })

      return response
    } catch (error) {
      console.error(error)
    }
  }

  getSystemUser = async () => {
    try {
      const res: AxiosResponse<IUser> = await this.api.get('/users/me')
      return res.data
    } catch (err) {
      console.error(err)
      throw err
    }
  }

  getProjects = async () => {
    try {
      const res: AxiosResponse<IProject[]> = await this.api.get('/projects')
      return res.data
    } catch (err) {
      console.error(err)
      throw err
    }
  }

  getAllProjectsForOwner = async (id: string) => {
    try {
      const res: AxiosResponse<IProject[]> = await this.api.get(
        `/projects/all/${id}`
      )
      return res.data
    } catch (err) {
      console.error(err)
      throw err
    }
  }

  getSingleProject = async (id: string) => {
    try {
      const res: AxiosResponse<IProject> = await this.api.get(`/projects/${id}`)
      return res.data
    } catch (err) {
      console.error(err)
    }
  }

  getElements = async (ids: string[], page: number = 1, size: number = 10) => {
    try {
      const res: AxiosResponse<any> = await this.api.post(`/elements`, {
        params: {
          page,
          size,
        },
        ids,
      })
      return res.data
    } catch (err) {
      console.error(err)
      throw err
    }
  }
  getMaterials = async () => {
    try {
      const res: AxiosResponse<any> = await this.api.get('/materials')

      return res.data
    } catch (err) {
      console.error(err)
      throw err
    }
  }

  getSingleMaterial = async (id: string) => {
    try {
      const res = await this.api.get(`/materials/${id}`)

      return res.data
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  /* API Users */
  getAllUsers = async () => {
    try {
      const res: AxiosResponse<any> = await this.api.get(`/users`)
      return res.data
    } catch (err) {
      console.error(err)
    }
  }

  getAllProjectOwners = async () => {
    try {
      const res: AxiosResponse<any> = await this.api.get(
        `/users/project-owners`
      )
      return res.data
    } catch (err) {
      console.error(err)
    }
  }

  deleteUser = async (id: string) => {
    try {
      await this.api.delete(`/users/${id}`)
    } catch (err) {
      console.error(err)
    }
  }

  uploadFiles = async (data: uploadFilesFormData) => {
    try {
      const formData = new FormData()
      formData.append('id', data.id)
      const files = Array.from(data.files)
      files.map((file: any) => formData.append('files', file))

      await this.api.post(`/upload`, formData)
    } catch (err) {
      console.error(err)
    }
  }

  /* Delete File (Image or PDF) */
  deleteFile = async (
    id: string,
    images: string[] | undefined,
    pdfs: string[] | undefined
  ) => {
    try {
      const res = await this.api.patch('/projects/files', {
        _id: id,
        images: images,
        files: pdfs,
      })
      return res.data
    } catch (err) {
      console.error(err)
    }
  }
}

const api = new Api()

Object.freeze(api)

export default api
