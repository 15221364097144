import React from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import TextInput from '../../components/TextInput'
import { createUser } from '../../redux/actions/auth'
import { useHistory } from 'react-router-dom'

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Invalid Email Address')
    .required('Email is required'),
  password: yup
    .string()
    .min(8, 'Must be 8 characters or more')
    .required('Password is required'),
  role: yup.string().required('Role is required'),
  profile: yup.object({
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required'),
  }),
})

const CreateUser = (): JSX.Element => {
  const dispatch = useDispatch()
  const history = useHistory()

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
  } = useFormik({
    initialValues: {
      email: '',
      password: '',
      role: '',
      profile: {
        firstName: '',
        lastName: '',
      },
    },
    validationSchema,
    onSubmit: () => {
      dispatch(createUser(values, history))
    },
  })

  return (
    <div>
      <div id="signup__container" className="ui container">
        <div className="ui raised teal segment">
          <h3 className="signup__title">Create User</h3>
          <form
            onSubmit={handleSubmit}
            id="signup__form"
            className="ui fluid form"
          >
            <TextInput
              name="email"
              id="email"
              label="Enter Email"
              placeholder="Email"
              icon="envelope icon"
              type="text"
              value={values.email}
              touched={touched.email}
              error={errors.email}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <TextInput
              name="password"
              id="password"
              label="Enter Password"
              placeholder="Password"
              icon="lock icon"
              type="password"
              value={values.password}
              touched={touched.password}
              error={errors.password}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <TextInput
              name="profile.firstName"
              id="profile.firstName"
              label="Enter First Name"
              placeholder="First Name"
              icon="user icon"
              type="text"
              value={values.profile.firstName}
              touched={touched.profile?.firstName}
              error={errors.profile?.firstName}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <TextInput
              name="profile.lastName"
              id="profile.firstName"
              label="Enter Last Name"
              placeholder="Last Name"
              icon="user icon"
              type="text"
              value={values.profile.lastName}
              touched={touched.profile?.lastName}
              error={errors.profile?.lastName}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />

            <div id="signup__field" className="field">
              <label className="ui__label">Pick a Role</label>
              <select
                name="role"
                value={values.role}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value="" label="Select a Role">
                  Select a Role
                </option>
                <option value="SuperAdmin" label="SuperAdmin">
                  SuperAdmin
                </option>
                <option value="ProjectOwner" label="ProjectOwner">
                  ProjectOwner
                </option>
                <option value="BIMExpert" label="BIMExpert">
                  BIMExpert
                </option>
              </select>
              {errors?.role && touched.role ? (
                <div id="errorMessage">{errors.role}</div>
              ) : null}
            </div>
            <button
              id="btn__signup"
              className="ui fluid button"
              style={{ backgroundColor: '#ffc000', color: 'white' }}
              type="submit"
            >
              Create User
            </button>
          </form>
          <div className="ui divider"></div>
        </div>
      </div>
    </div>
  )
}

export default CreateUser
