import { Header } from 'semantic-ui-react'
import SocialLinks from '../../components/SocialLinks'
import ContactImage from '../../images/Contact.png'

const Contact = () => {
  return (
    <div className="dashboard">
      <Header
        as="h1"
        style={{ backgroundColor: 'transparent', padding: '10px 0' }}
      >
        Contact
      </Header>
      <div>
        <label>Contact:</label>
        <br></br>
        <a>buro@4darchitects.nl</a>
        <br></br>
        <br></br>
        <p>
          <strong>Address: 4D architects BV</strong>
          <br></br>
          <strong>Singel 428/2</strong>
          <br></br>
          <strong>1017AV Amsterdam</strong>
          <br></br>
        </p>
      </div>
      <img
        src={ContactImage}
        height={500}
        alt="AboutUs"
        className="coverImage"
      />
      <br></br>
      <br></br>
      <SocialLinks />
    </div>
  )
}

export default Contact
