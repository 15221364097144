import React from 'react'
import { Card, Button, Header } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import SocialLinks from '../../components/SocialLinks'

import CardProjectsImage from '../../images/CardProjectsImage.jpg'
import RBDPrinciples from '../../images/RBDPrinciples.jpg'
import RBDInstruments from '../../images/RBDInstruments.png'
import CircularityIndex from '../../images/CircularityIndex.jpg'

const Dashbaord = () => {
  return (
    <div>
      <div className="dashboard" /*textAlign="justified"*/>
        <Header
          as="h1"
          style={{ backgroundColor: 'transparent', padding: '10px 0' }}
        >
          Reversible Building Design Platform
          <Header.Subheader style={{ backgroundColor: 'transparent' }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </Header.Subheader>
        </Header>
        <Card.Group itemsPerRow="4" stackable doubling>
          <Card color="teal" raised>
            <img
              src={RBDPrinciples}
              height={250}
              alt="RBDPrinciples"
              className="coverImage"
            />

            <Card.Content>
              <Card.Header style={{ backgroundColor: 'white' }}>
                Exemplary projects
              </Card.Header>
              <Card.Meta>Circular Building Projects</Card.Meta>
              <Card.Description>
                Overview of best practices of circular building to take
                advantage of. Also a sub-category with general building projects
                for measuring...
              </Card.Description>
            </Card.Content>
            <Card.Content extra textAlign="center">
              <Link to="/RBDPrincipleGuidelines">
                <Button className="cardButtons">Read More</Button>
              </Link>
            </Card.Content>
          </Card>
          <Card color="teal" raised>
            <img
              src={CardProjectsImage}
              height={250}
              alt="CardProjects"
              className="coverImage"
            />
            <Card.Content>
              <Card.Header style={{ backgroundColor: 'white' }}>
                RBD Projects
              </Card.Header>
              <Card.Meta>BIM Projects</Card.Meta>
              <Card.Description>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum
              </Card.Description>
            </Card.Content>
            <Card.Content extra textAlign="center">
              <Link to="/fallback">
                <Button className="cardButtons">Read More</Button>
              </Link>
            </Card.Content>
          </Card>
          <Card color="teal" raised>
            <img
              src={RBDInstruments}
              height={250}
              alt="RBDInstruments"
              className="coverImage"
            />
            <Card.Content>
              <Card.Header style={{ backgroundColor: 'white' }}>
                Circular Building
              </Card.Header>
              <Card.Meta>Tools</Card.Meta>
              <Card.Description>
                Information and design specification tools to support
                reversible/circular construction for all sectors. Think of
                circularity assessments, material inventories, life cycle
                analyses, energy performance, etc.
              </Card.Description>
            </Card.Content>
            <Card.Content extra textAlign="center">
              <Link to="/RBDInstruments">
                <Button className="cardButtons">Read More</Button>
              </Link>
            </Card.Content>
          </Card>
          <Card color="teal" raised>
            <img
              src={CircularityIndex}
              height={250}
              alt="CircularityIndex"
              className="coverImage"
            />
            <Card.Content>
              <Card.Header style={{ backgroundColor: 'white' }}>
                Circular Building Design
              </Card.Header>
              <Card.Meta>Guidelines</Card.Meta>
              <Card.Description>
                Howto's for reversible building design that helps avoiding waste
                and carbon emissions
              </Card.Description>
            </Card.Content>
            <Card.Content extra textAlign="center">
              <Link to="/CircularBuildingDesign">
                <Button className="cardButtons">Read More</Button>
              </Link>
            </Card.Content>
          </Card>
        </Card.Group>
      </div>
      <SocialLinks />
    </div>
  )
}

export default Dashbaord
