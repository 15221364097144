import React from 'react'
import { Card, Button, Header } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import SocialLinks from '../../components/SocialLinks'

import TransformationCapacity from '../../images/TransformationCapacity.jpeg'
import ReusePotential from '../../images/ReusePotential.jpg'
import ReversibleBIM from '../../images/ReversibleBIM.png'
import DesignMatrix from '../../images/DesignMatrix.png'

const RBDInstruments = () => {
  return (
    <div>
      <div className="dashboard" /*textAlign="justified"*/>
        <Header
          as="h1"
          style={{ backgroundColor: 'transparent', padding: '10px 0' }}
        >
          Circular Building - Tools
          <Header.Subheader style={{ backgroundColor: 'transparent' }}>
            Information and design specification tools to support
            reversible/circular construction for all sectors. Think of
            circularity assessments, material inventories, life cycle analyses,
            energy performance, etc.
          </Header.Subheader>
        </Header>
        <Card.Group itemsPerRow="4" stackable doubling>
          <Card color="teal" raised>
            <img
              src={TransformationCapacity}
              height={250}
              alt="TransformationCapacity"
              className="coverImage"
            />

            <Card.Content>
              <Card.Header style={{ backgroundColor: 'white' }}>
                Spatial reuse assessment
              </Card.Header>
              <Card.Meta>Transformation Capacity Tool</Card.Meta>
              <Card.Description>
                Transformation capacity tool measures the capacity of space and
                structure to accommodate different functions without causing
                major reconstruction works, demolition and material loss. The
                less effort needed to modify a building to meet new needs, the
                higher its transformation capacity. The greater the variety and
                number of modification options (reuse options of buildings), the
                higher the transformation capcity of the core.
              </Card.Description>
            </Card.Content>
            <Card.Content extra textAlign="center">
              <Link to="/fallback">
                <Button className="cardButtons">Read More</Button>
              </Link>
            </Card.Content>
          </Card>
          <Card color="teal" raised>
            <img
              src={ReusePotential}
              height={250}
              alt="ReusePotential"
              className="coverImage"
            />
            <Card.Content>
              <Card.Header style={{ backgroundColor: 'white' }}>
                Reuse Potential
              </Card.Header>
              <Card.Meta>BIM Projects</Card.Meta>
              <Card.Description>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum
              </Card.Description>
            </Card.Content>
            <Card.Content extra textAlign="center">
              <Link to="/fallback">
                <Button className="cardButtons">Read More</Button>
              </Link>
            </Card.Content>
          </Card>
          <Card color="teal" raised>
            <img
              src={ReversibleBIM}
              height={250}
              alt="ReversibleBIM"
              className="coverImage"
            />
            <Card.Content>
              <Card.Header style={{ backgroundColor: 'white' }}>
                Digital assesment of building reversibiity and reuse potential
                of materials
              </Card.Header>
              <Card.Meta>Reversible BIM</Card.Meta>
              <Card.Description>
                Reversible BIM is a digital tool that provides insight into the
                potential of buildings to be deconstructed with high recove...
              </Card.Description>
            </Card.Content>
            <Card.Content extra textAlign="center">
              <Link to="/projects">
                <Button className="cardButtons">Read More</Button>
              </Link>
            </Card.Content>
          </Card>
          <Card color="teal" raised>
            <img
              src={DesignMatrix}
              height={250}
              alt="DesignMatrix"
              className="coverImage"
            />
            <Card.Content>
              <Card.Header style={{ backgroundColor: 'white' }}>
                Design Matrix/Check List
              </Card.Header>
              <Card.Meta>Description of Projects</Card.Meta>
              <Card.Description>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum
              </Card.Description>
            </Card.Content>
            <Card.Content extra textAlign="center">
              <Link to="/fallback">
                <Button className="cardButtons">Read More</Button>
              </Link>
            </Card.Content>
          </Card>
        </Card.Group>
      </div>
      <SocialLinks />
    </div>
  )
}

export default RBDInstruments
