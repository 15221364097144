import { Header } from 'semantic-ui-react'
import SocialLinks from '../../components/SocialLinks'

const AboutUs = () => {
  return (
    <div>
      <div className="dashboard">
        <Header
          as="h1"
          style={{ backgroundColor: 'transparent', padding: '10px 0' }}
        >
          About us
        </Header>
        <p>
          Revisable Building Design Platform (RBD) is collection of all
          reversible building design guidelines, protocols and tools developed
          by Prof. Dr. Elma Durmisevic with her vision of circular built
          environment in which{' '}
          <strong>demolition is cosnidered a Design Mistake</strong>
          (Elma Durmisevic 2015). Set of tools, guidelines and protocols are
          aiming at enabling designers, construction industry, building owners
          and policy makers to avoid demolition and develop circular buildings
          as a <strong>flexible monument of the future</strong>.(Elma Durmisevic
          2017)
        </p>
        <br></br>
        <p>
          Tools, guidelines and protocols on the RBD platform have been during
          H2020 BAMB project and Interreg Digital Deconstruction project. Tools
          and guidelines are adopted by Brussels Region as a way to unlock
          circular potential from the building stock and Region of Parkstad The
          Netherlands works toward their implementation.
        </p>
      </div>
      <SocialLinks />
    </div>
  )
}

export default AboutUs
