import { Button, Card, Header } from 'semantic-ui-react'
import SocialLinks from '../../components/SocialLinks'
import { Link } from 'react-router-dom'
import CircularityIndex from '../../images/CircularityIndex.jpg'

const RBDPrincipleGuidelines = () => {
  return (
    <div>
      <div className="dashboard">
        <Header
          as="h1"
          style={{ backgroundColor: 'transparent', padding: '10px 0' }}
        >
          Exemplary Projects - Circular Building Projects
          <Header.Subheader style={{ backgroundColor: 'transparent' }}>
            Overview of best practices of circular building to take advantage
            of. Also a sub-category with general building projects for measuring
            regional material flows.
          </Header.Subheader>
        </Header>
        <Card.Group itemsPerRow="4" stackable doubling>
          <Card color="teal" raised>
            <img
              src={CircularityIndex}
              height={250}
              alt="CircularityIndex"
              className="coverImage"
            />
            <Card.Content>
              <Card.Header style={{ backgroundColor: 'white' }}>
                Circular Building Design
              </Card.Header>
              <Card.Meta>Guidelines</Card.Meta>
              <Card.Description>
                Howto's for reversible building design that helps avoiding waste
                and carbon emissions
              </Card.Description>
            </Card.Content>
            <Card.Content extra textAlign="center">
              <Link to="/CircularBuildingDesign">
                <Button className="cardButtons">Read More</Button>
              </Link>
            </Card.Content>
          </Card>
        </Card.Group>
      </div>
      <SocialLinks />
    </div>
  )
}

export default RBDPrincipleGuidelines
