import React from 'react'
import {
  Container,
  Header,
  Segment,
  Button,
  Card,
  Image,
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import ExcelSheetImage from '../../images/Excel_Sheet.png'
import RevitIcon from '../../images/revitIcon.png'
import pdfIcon from '../../images/pdfIcon.png'
import protocolIcon from '../../images/protocolIcon.png'

const BIMScreen: React.FC = (): JSX.Element => {
  return (
    <Container className="container__toolkit" textAlign="justified">
      <Header
        style={{
          backgroundColor: 'white',
          textAlign: 'center',
        }}
        as="h1"
        attached="top"
      >
        Reversible BIM Toolkit
      </Header>
      <Segment attached>
        <Card.Group itemsPerRow="4" stackable doubling>
          <Card color="teal">
            <Image src={RevitIcon} alt="BIMImage" wrapped />
            <Card.Content>
              <Card.Header style={{ backgroundColor: 'white' }}>
                Plugin
              </Card.Header>
              <Card.Meta>Autodesk Revit Plugin</Card.Meta>
              <Card.Description>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum
              </Card.Description>
            </Card.Content>
            <Card.Content extra textAlign="center">
              <Button style={{ backgroundColor: '#ffc000', color: 'black' }}>
                Read More
              </Button>
            </Card.Content>
          </Card>
          <Card color="teal">
            <Image src={ExcelSheetImage} ui={false} wrapped alt="BIMImage" />
            <Card.Content>
              <Card.Header style={{ backgroundColor: 'white' }}>
                Reusepotential Lite
              </Card.Header>
              <Card.Meta>Reusepotential Lite</Card.Meta>
              <Card.Description>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum
              </Card.Description>
            </Card.Content>
            <Card.Content extra textAlign="center">
              <Button style={{ backgroundColor: '#ffc000', color: 'black' }}>
                Read More
              </Button>
            </Card.Content>
          </Card>
          <Card color="teal">
            <Image src={pdfIcon} ui={false} wrapped alt="BIMImage" />
            <Card.Content>
              <Card.Header style={{ backgroundColor: 'white' }}>
                Transformation Capacity
              </Card.Header>
              <Card.Meta> Transformation Capacity</Card.Meta>
              <Card.Description>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum
              </Card.Description>
            </Card.Content>
            <Card.Content extra textAlign="center">
              <Link to="/toolkit">
                <Button style={{ backgroundColor: '#ffc000', color: 'black' }}>
                  Read More
                </Button>
              </Link>
            </Card.Content>
          </Card>
          <Card color="teal">
            <Image src={protocolIcon} ui={false} wrapped alt="BIMImage" />
            <Card.Content>
              <Card.Header style={{ backgroundColor: 'white' }}>
                Reversible BIM Description
              </Card.Header>
              <Card.Meta>Description of Projects</Card.Meta>
              <Card.Description>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum
              </Card.Description>
            </Card.Content>
            <Card.Content extra textAlign="center">
              <Button style={{ backgroundColor: '#ffc000', color: 'black' }}>
                Read More
              </Button>
            </Card.Content>
          </Card>
        </Card.Group>
      </Segment>
    </Container>
  )
}

export default BIMScreen
