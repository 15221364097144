type UserState = {
  _id: string
  email: string
  role: string
}

type ProjectOwnersState = {
  _id: string
  email: string
  profile: {
    firstName: string
    lastName: string
  }
}

type UsersReducerType = {
  allUsers: UserState[]
  allProjectOwners: ProjectOwnersState[]
}

export const UsersReducer = (
  state: UsersReducerType = {
    allUsers: [],
    allProjectOwners: [],
  },
  action: Action<string, UserState[]>
): UsersReducerType => {
  const { type, payload } = action

  switch (type) {
    case 'GET_USERS_SUCCESS':
      return {
        ...state,
        allUsers: payload as UserState[],
      }
    case 'GET_PROJECTOWNERS_SUCCESS':
      return {
        ...state,
        allProjectOwners: payload as allProjectOwners[],
      }
    default:
      return state
  }
}
